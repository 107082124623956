.resume-main-wrapper {
    margin-top: 120px;
}
.main-body {
    /* background: #72c3f1; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
    overflow-x: hidden;
}

.qr-image canvas {
    width: 100px !important;
    height: 100px !important;
}
/* .qr-canvas{
    width: 20px !important;
} */
.resume-gap {
    margin-right: 20px;
}
.resume-1 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    margin: 0px 0 20px 0;
    display: grid;
    grid-template-columns: 30% 70%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.resume-1 .left-side-1 {
    position: relative;
    background: #004382;
    padding: 40px 0 40px 30px;
    color: #ffffff;
    word-break: break-all;
}
.profile-text-1 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.profile-img-1 {
    width: 90%;
    text-align: center;
}

.profile-img-1 img {
    width: 170px;
    height: 170px;
    position: relative;
    z-index: 1;
    border: 2px solid #ffffff;
    border-radius: 50%;
    object-fit: cover;
}

.resume-1 .right-side-1 {
    position: relative;
    background: #ffffff;
    padding: 30px;
}
.sub-heading-1 {
    color: #ffffff;
    /* font-family: Inter; */
    text-align: left;
    font-size: 18px;
    padding: 15px 0 0 0;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
    padding-bottom: 6px;
    font-weight: 600;
}
.contact-details-1 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    word-break: break-all;
    padding: 4px 10px 10px 0;
    line-height: 140%;
}
.contact-details-1:hover a {
    color: #ffffff;
}
.contact-heading-1 {
    margin-top: 10px;
}

.education-1-details-1 {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
}
.education-1 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.skill-1 {
    list-style-type: none;
    padding: 10px 0 10px 0;
    font-size: 14px;
}

.language-1 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
}
.interest-1 {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 16px;
    padding-top: 10px;
}

.interest-1 li {
    box-sizing: border-box;
}

.resume-name-1 {
    color: #004382;
    font-size: 35px;
    font-weight: 400;
}
.first-name-1 {
    color: #004382;
    font-size: 35px;
    font-weight: 900;
}
.resume-1-display {
    display: flex;
    justify-content: space-between;
}
.resume-designation-1 {
    font-weight: 400;
    text-align: left;
    padding-bottom: 10px;
    color: #000000;
}
.resume-about-1 {
    font-size: 16px;
    font-weight: 400;
    padding: 15px 0 20px 0;
}
.experience-1 {
    font-weight: 700;
    color: #004382;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.resume-experience-1 {
    position: relative;
    padding: 10px 0;
}

.job-1 {
    list-style: none;
    position: relative;
    /* padding: 0 0 20px 0; */
    display: flex;
    align-items: center;
}

.job-1-year {
    font-weight: bold;
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
}

.job-content {
    box-sizing: border-box;
}

.company-name-1 {
    padding: 5px 0;
}

.job-1-position {
    padding: 5px 0;
}

.job-1-description {
    text-align: justify;
    padding-bottom: 20px;
}

.job-1:last-child .job-1-description {
    padding-bottom: 0;
}

.reference-1 {
    font-weight: 700;
    color: #004382;
    padding-block: 10px;
    border-bottom: 2px solid #000;
    text-transform: uppercase;
}
.resume-reference-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0;
}

.ref {
    flex: 0 1 calc(50% - 20px);
    box-sizing: border-box;
}
.resume-right-1 .ref {
    color: black;
    font-size: 14px;
}

.resume-right-1 .ref span {
    font-weight: bolder;
}
.ref-name-1 {
    padding: 4px 0 4px 0;
}
.ref-position-1,
.ref-phone-1,
.ref-email-1 {
    padding: 3px 0 3px 0;
    display: flex;
}
.contact-link,
.contact-link-1 {
    color: inherit;
    text-decoration: none;
}

.contact-link:hover {
    color: #004382;
    text-decoration: underline;
}
.contact-link-1:hover {
    text-decoration: underline;
}
.resume-3-email {
    word-break: break-all;
}
@media screen and (max-width: 768px) {
    .right-resume-image img {
        width: 100%;
        height: auto;
    }
    .profile-img-1 img {
        width: 23vw;
        height: 23vw;
        object-fit: cover;
        text-align: center;
    }
    .upper-container-3 {
        grid-template-columns: 65% 35%;
    }
    .lower-container-3 {
        grid-template-columns: 65% 35%;
    }
    .resume-1 .right-side-1 {
        padding: 25px;
    }
    .job-content {
        padding-left: 0px;
    }
    .resume-1 .left-side-1 {
        padding: 30px 0 30px 22px;
    }
    .qr-image canvas {
        width: 15vw !important;
        height: 15vw !important;
    }
    .resume-main-wrapper {
        margin-top: 60px;
    }
    .main-body {
        transform: scale(0.95);
    }
    .main-body-2 {
        transform: scale(0.95);
    }
    .resume-download-pdf {
        margin-top: 0 !important;
        font-size: 15px !important;
    }
    .resume-download-pdf button {
        font-size: 15px !important;
    }
    .education-1 h4 {
        font-size: 12px;
    }
    .resume-1 {
        grid-template-columns: 35% 65%;
    }
    .resume-name-1 .qr-image {
        align-items: start;
    }
    .ref {
        flex: 100%;
    }
}
@media screen and (max-width: 576px) {
    .resume-1 .right-side-1 {
        padding: 15px;
    }
    .resume-name-1 .qr-image {
        align-items: start;
    }
    .sub-heading-1,
    .job-1-position,
    .ref-name-1 {
        font-size: 12px;
    }
    .company-name-1 {
        font-size: 13px;
        padding: 0;
    }
    .experience-1,
    .reference-1 {
        font-size: 15px;
    }
    .resume-1 .left-side-1 {
        padding: 25px 0 25px 15px;
    }
    .contact-link-1,
    .contact-details-1,
    .education-1-details-1,
    .skill-1,
    .language-1,
    .interest-1 {
        font-size: 9px;
        word-break: break-all;
    }
    .resume-about-1,
    .job-1-description {
        font-size: 12px;
        line-height: 150%;
        text-align: inherit;
    }
    .degree-1,
    .contact-heading-1,
    .job-1-year,
    .ref-position-1,
    .ref-phone-1,
    .ref-email-1 {
        font-size: 14px;
    }

    .skill-1 li {
        width: 100%;
        padding: 0px 0;
        line-height: 170%;
    }
    .resume-name-1,
    .first-name-1 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .resume-designation-1 {
        font-size: 12px;
        line-height: 140%;
    }
    .resume-about-1 {
        padding: 10px 0 10px 0;
    }
    .resume-gap {
        margin-right: 15px;
    }
    .job-1-description {
        padding-bottom: 15px;
    }

    .resume-1-display .qr-image {
        display: flex;
        align-items: start;
    }
}
@media screen and (max-width: 425px) {
    .resume-1 .right-side-1 {
        padding: 10px;
    }
    .resume-1 .left-side-1 {
        padding: 20px 10px 20px 10px;
    }
    .contact-link-1,
    .contact-details-1,
    .education-1-details-1,
    .skill-1,
    .language-1,
    .interest-1 {
        font-size: 9px;
    }
    .resume-about-1,
    .job-1-description {
        font-size: 13px;
    }
}
