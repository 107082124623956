.bio-temp-11 {
    display: grid;
    grid-template-columns: 50% 20% 25%;
    gap: 20px;
    padding: 15px 20px 0;
    align-items: end;
    word-break: break-all;
}
.sidbar-temp-11 {
    background: #ffffff;
    padding: 40px;
    color: #000000;
}
.edu-name-11 {
    font-size: 14px;
}
.resume-11 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    margin: 0px 0 20px 0;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.name-temp-11 {
    font-size: 42px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
}
.name-temp-11 span {
    font-size: 42px;
    font-weight: 900;
    margin-right: 15px;
    text-align: center;
    margin-top: 20px;
}
.experience-11-list {
    border-bottom: 1px solid #d9d9d9;
}
.experience-11-list:last-child {
    border-bottom: none;
}

/* responsive  */
@media screen and (max-width: 768px) {
    .resume-11 {
        transform: scale(0.95);
    }
    .resume-11 p {
        font-size: 14px;
        line-height: 140%;
        text-align: inherit !important;
    }
    .bio-temp-11-dis {
        margin-left: 0 !important;
    }
    .name-temp-11-main {
        padding: 20px 20px !important;
    }
    .sidbar-temp-11 {
        padding: 20px 10px !important;
        word-break: break-all;
    }
    .sidbar-temp-11 h2 {
        font-size: 15px !important;
    }
    .sidbar-temp-11-img {
        width: 100%;
       
    }
    .sidbar-temp-11-main {
        grid-template-columns: 35% 65% !important;
    }
    .sidbar-temp-right-11 {
        padding: 20px 10px !important;
        word-break: break-all;
    }
}

/* responsive  */
@media screen and (max-width: 576px) {
    .bio-temp-11 {
        display: grid;
        grid-template-columns: 1.5fr;
        gap: 10px;
        word-break: break-all;
        padding: 15px 20px 0;
    }
    .sidbar-temp-11 {
        background: #ffffff;
        padding: 5px;
        color: #000000;
    }
    .name-temp-11 span {
        font-size: 25px;
        font-weight: 900;
        margin-right: 15px;
        text-align: center;
        margin-top: 20px;
    }

    .name-temp-11 {
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        margin-top: 20px;
    }
}
