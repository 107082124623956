/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial Narrow", Arial, sans-serif;
} */

.main-body-15 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #32353a;
    overflow-x: hidden;
}

/* .qr-image-15 img {
  width: 100px;
} */
.resume-gap-15 {
    margin-right: 20px;
}
.resume-12 {
    color: #000000 !important;
}
.resume-15 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    display: grid;
    grid-template-columns: 30% 70%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.resume-15 .left-side-15 {
    position: relative;
    background-color: #32353a;
    /* padding:0 20px 20px 20px; */
    color: #000000;
}
.profile-text-15 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.profile-img-15 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-img-15 img {
    width:236px;
    height: 236px;
}

.resume-15 .right-side-15 {
    position: relative;
    padding: 20px;
    word-break: break-all;
}
.sub-heading-15 {
    color: #b3835a;
    text-align: left;
    font-size: 18px;
    border-bottom: 2px solid #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 0 6px 0;
}
.sub-heading-12 {
    color: #000;
    border-bottom: 2px solid #000;
}
.contact-details-15 {
    color: #fff;
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 4px 10px 10px 0;
    line-height: 140%;
}
.contact-heading-15 {
    margin-top: 10px;
    color: #fff;
}

.education-15-details-15 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
}
.education-15 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.skill-15 {
    padding: 10px 0 10px 0px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
}

.skill-15 li {
    width: 100%;
    padding: 5px 0;
    color: #fff;
    font-size: 14px;
}
.language-15 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
}

.language-15 li {
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    color: #ffffff;
    font-size: 14px;
}

.interest-15 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 16px;
}

.interest-15 li {
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    color: #ffffff;
    font-size: 14px;
}

.resume-name-15 {
    color: #fff;
    font-size: 27px;
    font-weight: 400;
    padding-left: 20px;
}
.resume-12-name {
    font-size: 20px;
}

.first-name-15 {
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    margin-right: 7px;
}
.first-name-12 {
    color: #000;
}
.resume-15-display {
    display: flex;
    justify-content: space-between;
}
.resume_15_name_designation {
    padding: 18px 0;
    background-color: #b3835a;
}
.resume-designation-15 {
    font-weight: 400;
    line-height: 19px;
    font-size: 18px;
    color: #fff;
    padding-left: 20px;
}
.resume-about-15 {
    font-size: 16px;
    font-weight: 400;
    /* padding: 15px 0 20px 0; */
}
.experience-15 {
    font-weight: 700;
    color: #b3835a;
    border-bottom: 2px solid #32353a;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 18px;
}

.resume-experience-15 {
    position: relative;
    padding: 20px 0 10px 0;
    /* display: flex; */
}
.resume-education-15 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.job-15 {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
}

.job-15-year {
    /* margin-bottom: 5px; */
    position: relative;
    z-index: 2;
}

.company-name-15 {
    padding: 2px 0;
}
.job-content-15 {
    display: grid;
    gap: 10px;
    grid-template-columns: 40% 58%;
}

.job-15-position {
    color: #b3835a;
    padding: 2px 0;
    font-size: 16px;
}

.job-15-description {
    padding-bottom: 20px;
}

.job-15:last-child .job-15-description {
    padding-bottom: 0;
}

.reference-15 {
    font-weight: 700;
    color: #b3835a;
    padding-block: 10px;
    border-bottom: 2px solid #32353a;
    text-transform: uppercase;
    font-size: 18px;
}
.resume-reference-15 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0;
}

.ref-15 {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
}
.resume-right-15 .ref-15 {
    color: black;
    font-size: 14px;
    word-break: break-all;
}

.resume-right-15 .ref-15 span {
    font-weight: bolder;
}
.ref-name-15 {
    padding: 4px 0 4px 0;
    font-size: 18px;
}
.ref-position-15,
.ref-phone-15,
.ref-email-15 {
    padding: 3px 0 3px 0;
}

.contact-link-15 {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-word;
}
.contact-link-15:hover{
    color: #FFFFFF;
}
.resume-contact-details-15 {
    padding: 5px 20px 20px;
    background-color: #32353a;
    word-break: break-all;
}
.degree-15 {
    color: #b3835a;
}
.resume-15 .left-side-12 {
    position: relative;
    background: #f1f1f1;
    padding: 20px 20px;
    color: #000000;
}

/* Responsive Design */

@media screen and (max-width: 768px) {
    /* .profile-img-15 img {
    width: 23vw;
    height: 23vw;
  } */
   .profile-img-15 img{
    width: 100%;
    height: auto;
   }
    .resume-12 {
        grid-template-columns: 35% 65%;
    }
    .resume-12-name {
        font-size: 16px;
        word-break: break-all;
    }
    .resume-name-15 {
        padding-left: 10px;
    }

    .resume-designation-15 {
        padding-left: 10px;
    }
    .language-15 li {
        padding: 0px 0;
    }
    .resume-15 .right-side-15 {
        padding: 25px;
    }
    .job-content-15 {
        display: block;
        padding-left: 0px;
    }
    /* .resume-15 .left-side-15 {
    padding: 30px 20px;
  } */
    /* .qr-image-15 img {
    width: 15vw;
    height: 15vw;
  } */
    .resume-name-15 {
        font-size: 22px;
    }
    .resume_15_name_designation h1 {
        font-size: 16px;
    }
    .interest-15 li {
        padding: 0px 10px 0 0;
        box-sizing: border-box;
        color: #ffffff;
    }
    .resume-contact-details-15 {
        padding: 5px 10px 10px;
        background-color: #32353a;
    }
    .resume-education-15 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
    }
    .resume-15 .left-side-12 {
        padding: 20px 10px;
    }
    .resume-gap-15 p {
        line-height: 140%;
    }
    .ref-15 {
        flex: 100% !important;
    }
}

@media screen and (max-width: 576px) {
    /* .resume-15 .left-side-15 {
    padding: 20px 12px;
  } */
    .resume-gap-15 {
        margin-right: 15px;
    }

    .resume-15 .right-side-15 {
        padding: 20px 10px;
    }
    .job-content-15 {
        padding-left: 0px;
    }
    .sub-heading-15,
    .job-15-position,
    .ref-name-15 {
        font-size: 16px;
    }
    .company-name-15 {
        font-size: 13px;
        padding: 0;
    }
    .experience-15,
    .reference-15 {
        font-size: 14px;
    }

    .contact-link-15,
    .contact-details-15,
    .education-15-details-15,
    .skill-15,
    .language-15,
    .interest-15 {
        font-size: 14px;
    }
    .contact-details-15 {
        padding: 4px 0px 5px 0;
    }
    .contact-heading-15 {
        margin-top: 5px;
    }

    .resume-about-15,
    .job-15-description {
        font-size: 14px;
    }
    .degree-15,
    .contact-heading-15,
    .job-15-year,
    .ref-position-15,
    .ref-phone-15,
    .ref-email-15 {
        font-size: 14px;
    }

    .skill-15 li {
        width: 100%;
        padding: 0px 0;
        font-size: 14px;
    }
    .resume-name-15,
    .first-name-15 {
        font-size: 16px;
    }
    .first-name-15 span {
        font-size: 16;
    }
    .resume-designation-15 {
        font-size: 16px;
    }
    .resume-about-15 {
        padding: 0px;
    }
    .job-15-description {
        padding-bottom: 15px;
    }

    .qr-image-15 {
        /* display: flex; */
        align-items: start !important;
    }
    .resume-gap-15 p {
        padding-top: 0;
    }
    .job-15::before {
        width: 7px;
        height: 7px;
    }
    .resume-about-15 {
        padding: 0;
    }
}

@media screen and (max-width: 425px) {
    /* .resume-15 .left-side-15 {
    padding: 20px 10px;
  } */

    .resume-15 {
        display: grid;
        grid-template-columns: 35% 65%;
    }
    .resume-designation-15 {
        font-size: 16px;
    }
    .resume-gap-15 {
        margin-right: 10px;
    }
    .resume-about-15 {
        padding: 5px 0 10px 0;
    }
    .contact-link-15,
    .contact-details-15,
    .education-15-details-15,
    .skill-15,
    .language-15,
    .interest-15 {
        font-size: 14px;
    }
    .resume-about-15,
    .job-15-description {
        font-size: 13px;
    }
    .first-name-15 {
        margin-right: 8px;
    }
}

/* template 12 */

.profile-img-12 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    margin: 10px 0px;
}

.profile-img-12 img {
    width: 170px;
    height: 170px;
    position: relative;
    z-index: 1;
    border: 5px solid #ffffff;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .profile-img-12 img {
        width: 23vw;
        height: 23vw;
           border: 2px solid #ffffff;
    }
}
