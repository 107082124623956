.main-body-4 {
    margin: 0 auto;
    min-height: 100vh;
    color: #000000;
    position: relative;
    max-width: 790px;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #ffffff;
}
.test-4 {
    position: relative;
    background-color: #e3707f;
    color: #ffffff;
    padding: 10px 30px 20px 30px;
}

.qr-image-4 img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.resume-gap-4 {
    margin-right: 20px;
}
.resume-4 {
    background: #ffffff;
    display: grid;
    box-shadow: 0 35px 55px rgba(0, 0, 0, 0.2);
    grid-template-columns: 30% 70%;
}

.resume-4 .left-side-4 {
    position: relative;
    background: #484848;
    padding: 30px;
    color: #000000;
}
.profile-text-4 {
    position: relative;
    display: flex;
    flex-direction: column;
}
.profile-4 {
    position: relative;
    background: #484848;
}

.profile-img-4 {
    width: 100%;
    position: relative;
    text-align: center;
    background: #484848;
    padding: 30px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-img-4::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    z-index: 1;
}
.profile-img-4 img {
    width: 170px;
    height: 170px;
    position: relative;
    z-index: 1;
    border: 2px solid #ffffff;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

.resume-4 .right-side-4 {
    background: #ffffff;
    padding: 30px;
}
.sub-heading-4 {
    color: #e3707f;
    text-align: left;
    font-size: 20px;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
    padding-bottom: 5px;
    font-weight: 700;
}
.contact-details-4 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 4px 10px 10px 0;
    line-height: 140%;
    color: #ffffff;
}
.contact-heading-4 {
    margin-top: 10px;
    color: #ffffff;
}
.education-heading-4 {
    color: #ffffff;
}

.education-4-details-4 {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
    color: #ffffff;
}
.education-4 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.skill-4 {
    list-style-type: none;
    padding: 10px 0 10px 0;
    font-size: 14px;
    color: #ffffff;
}

.skill-4 li {
    padding: 5px 0;
}
.language-4 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
    color: #ffffff;
}

.language-4 li {
    box-sizing: border-box;
}

.interest-4 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 16px;
    color: #ffffff;
}

.interest-4 li {
    box-sizing: border-box;
}

.resume-name-4 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 400;
}
.first-name-4 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 900;
    margin-right: 10px;
}
.resume-4-display {
    display: flex;
    justify-content: space-between;
}
.resume-designation-4 {
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 2px;
    text-align: left;
    color: #ffffff;
    padding: 10px 0;
}
.resume-about-4 {
    font-size: 16px;
    font-weight: 400;
    /* text-align: justify; */
}
.experience-4 {
    font-weight: 700;
    color: #484848;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.resume-experience-4 {
    position: relative;
    padding: 10px 0;
}

.job-4 {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
}

.job-4-year {
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
}
.job-content-4 {
    box-sizing: border-box;
}

.company-name-4 {
    padding: 5px 0;
}

.job-4-position {
    padding: 5px 0;
    font-size: 16px;
}

.job-4-description {
    /* text-align: justify; */
    padding-bottom: 20px;
}

.job-4:last-child .job-4-description {
    padding-bottom: 0;
}

.reference-4 {
    font-weight: 700;
    color: #484848;
    padding-block: 5px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
}
.resume-reference-4 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0;
}

.ref-4 {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
}
.resume-right-4 .ref-4 {
    color: black;
    font-size: 14px;
}

.resume-right-4 .ref-4 span {
    font-weight: bolder;
}
.ref-name-4 {
    padding: 4px 0 4px 0;
    font-size: 18px;
}

.contact-link-44,
.contact-link-4 {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-word;
}

.contact-link-44:hover {
    color: #e3707f;
    text-decoration: underline;
}
.contact-link-4:hover {
    color: #e3707f;
    text-decoration: underline;
}
.resume-up {
    display: grid;
    grid-template-columns: 30% 70%;
}

/* Responsive Design */

@media screen and (max-width: 768px) {
    .profile-img-4 img {
        width: 19vw;
        height: 19vw;
    }
    .resume-12-name{
        font-size: 16px;
    }
    .resume-12-name span{
        font-size: 16px;
    }
    .qr-image-12{
        align-items: start !important;
    }
    .resume-4 .right-side-4 {
        padding: 25px;
    }
    .resume-4 .right-side-4 p{
        font-size: 14px;
    }
    .resume-gap-15 p {
        text-align: inherit !important;
    }
    .job-content-4 {
        padding-left: 25px;
    }
    .resume-4 .left-side-4 {
        padding: 30px 20px;
    }
    .qr-image-4 img {
        width: 12vw;
        height: 12vw;
    }
    .main-body-4 {
        transform: scale(0.95);
    }
    .resume-name-4,
    .first-name-4 {
        font-size: 20px;
    }
    .resume-4-display .qr-image {
        align-items: start;
    }
}

@media screen and (max-width: 576px) {
    .test-4 {
        padding: 15px;
    }
    .resume-4 .left-side-4 {
        padding: 15px;
    }
    .resume-4 .right-side-4 {
        padding: 15px;
    }
    .job-content-4 {
        padding-left: 15px;
    }
    .sub-heading-4,
    .job-4-position,
    .ref-name-4 {
        font-size: 14px;
    }
    .sub-heading-4 {
        padding-top: 20px;
    }

    .sub-heading-4:first-child {
        padding-top: 0px;
    }
    .company-name-4 {
        font-size: 13px;
        padding: 0;
    }
    .experience-4,
    .reference-4 {
        font-size: 18px;
    }

    .contact-link-4,
    .contact-details-4,
    .education-4-details-4,
    .skill-4,
    .language-4,
    .interest-4 {
        font-size: 13px;
        word-break: break-all;
    }
    .contact-details-4 {
        padding: 4px 10px 5px 0;
    }
    .contact-heading-4 {
        margin-top: 5px;
    }
    .resume-about-4,
    .job-4-description {
        font-size: 12px;
        line-height: 150%;
    }
    .contact-heading-4,
    .education-heading-4,
    .job-4-year,
    .ref-position-4,
    .ref-phone-4,
    .ref-email-4 {
        font-size: 12px;
    }

    .skill-4 li {
        width: 100%;
        padding: 0px 0;
    }
    .resume-designation-4 {
        font-size: 16px;
        padding: 5px 0;
    }
    .resume-about-4 {
        padding: 0;
    }
    .job-4-description {
        padding-bottom: 15px;
    }
    .ref-4 {
        flex: 100%;
    }
    .qr-image-4 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .job-content-4 {
        padding-left: 0px;
    }
    .job-4::before {
        width: 7px;
        height: 7px;
    }

    .timeline-line-4 {
        left: 4px;
    }
    .resume-gap-4 {
        margin-right: 15px;
    }
}

@media screen and (max-width: 425px) {
    .profile-img-4 img {
        width: 24vw;
        height: 24vw;
    }
    .resume-4 .right-side-4 {
        padding: 10px;
    }
    .resume-4 {
        display: grid;
        grid-template-columns: 35% 65%;
    }
    .resume-up {
        display: grid;
        grid-template-columns: 35% 65%;
    }

    .resume-designation-4 {
        font-size: 16px;
    }
    .resume-gap-4 {
        margin-right: 10px;
    }
    /* .resume-about-4 {
        padding: 5px 0 10px 0;
        } */
    .contact-link-4,
    .contact-details-4,
    .education-4-details-4,
    .skill-4,
    .language-4,
    .interest-4 {
        font-size: 11px;
    }

    .resume-reference-4 {
        gap: 10px;
    }
    .test-4 {
        padding: 10px;
    }
    .profile-img-4 {
        padding: 40px 0px 0px 0px;
    }
}
@media screen and (max-width: 375px) {
}
