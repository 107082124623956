.main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
}
.main-section {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    display: grid;
    grid-template-columns: 28% 72%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow-x: hidden;
}

/* left side css start from here */

.left-side-6 {
    position: relative;
    background: #484848;
    padding: 20px 30px 30px;
    color: #ffffff;
    word-break: break-all;
}
.left-side-6 .qr-image {
    padding-top: 15px;
}
.profile-image-6 {
    width: 205px;
    height: 205px;
    object-fit: cover;
    position: relative;
    z-index: 1;
}
.qr-image-6 {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 20px;
}

.sub-heading-6 {
    color: #ffb317;
    text-align: left;
    font-size: 18px;
    padding: 5px 0 0 0;
    border-bottom: 2px solid #ffffff;
    text-transform: uppercase;
    padding-bottom: 6px;
    font-weight: 600;
}
.contact-list {
    padding-left: 0;
}

.contact-item {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 4px 10px 10px 0;
    line-height: 140%;
}

.contact-link {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word; /* Wrap long words */
    word-break: break-word;
}

.contact-link:hover {
    color: #ffb317;
    text-decoration: underline;
}
.education-list {
    margin: 10px 0;
    padding-left: 0;
}

.education-item {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
}

.education-container {
    margin: 20px 0;
}

.skill-6 {
    list-style-type: none;
    padding: 10px 0 10px 0;
    font-size: 13px;
    color: #ffffff;
}

.language-list {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
}

.language-item {
    box-sizing: border-box;
}

.hobbies-list {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 16px;
}

.hobbies-item {
    box-sizing: border-box;
}

/* right side starts from here */

.right-side-6 {
    position: relative;
    background: #ffffff;
    padding: 30px;
}

.name-heading {
    color: #ffb317;
    font-size: 42px;
    font-weight: 400;
}

.name-heading span {
    color: #ffb317;
    font-size: 42px;
    font-weight: 700;
    margin-right: 15px;
}

.designation {
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 2px;
    text-align: left;
    color: #000000;
    margin-top: 10px;
}

.description {
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0 30px 0;
    text-align: justify;
}
.section-heading {
    font-weight: 700;
    color: #ffb317;
    padding: 8px 0 8px 20px;
    background-color: #484848;
    text-transform: uppercase;
}

.experience-list {
    padding: 10px 0 10px 0px;
}

.experience-item {
    list-style: none;
    padding: 10px 0 10px 0px;
}

/* .job-6-year {
        font-weight: 700;
    } */

.company-info {
    padding: 5px 0;
}

.job-position {
    padding: 5px 0;
    font-size: 18px;
    font-weight: bold;
}

.job-description {
    text-align: justify;
}

.section-heading {
    font-weight: 700;
    color: #ffb317;
    padding: 8px 0 8px 20px;
    background-color: #484848;
}

.references {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0px;
    font-size: 14px;
}

.reference-item {
    color: black;
    line-height: 15px;
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
    font-size: 15px;
}

.reference-item h3 {
    padding: 4px 0;
    font-size: 18px;
    font-weight: bold;
}

.reference-item p {
    padding: 3px 0;
    margin: 0;
}

.reference-item a {
    color: inherit;
    text-decoration: none;
}

.reference-item a:hover {
    color: #ffb317;
    text-decoration: underline;
}

.reference-item span {
    font-weight: bold;
}
@media screen and (max-width: 768px) {
.right-side-6 .description{
    text-align: inherit;
    line-height: 140%;
}
.right-side-6 {
    word-break: break-all;
}
.job-description {
    text-align: inherit;
    line-height: 140%;
}
.name-heading span {
    margin-right: 5px;
}
.right-side-6 .company-info{
    padding: 0;
}
}
@media screen and (max-width: 568px) {
    .profile-image-6 {
        width: 27vw;
        height: auto;
    }
    .right-side-6 {
        padding: 20px;
    }

    .left-side-6 {
        padding: 20px 15px;
    }
    .qr-image-6 {
        width: 15vw;
        height: 15vw;
        margin-bottom: 10px;
    }
    .name-heading {
        font-size: 22px;
    }

    .name-heading span {
        font-size: 20px;
    }
    .designation {
        margin-top: 0px;
        font-size: 16px;
    }
    .description,
    .job-description {
        font-size: 12px;
    }
    .job-6-year,
    .company-info,
    .contact-title,
    .education-title {
        font-size: 14px;
    }
    .contact-link,
    .education-item,
    .language-item,
    .hobbies-item {
        font-size: 13px;
    }
    .skill-6 li {
        width: 100%;
        padding: 5px 0;
    }
    .skill-6 li:last-child {
        padding-bottom: 0;
    }
    .sub-heading-6,
    .job-4-position,
    .ref-name-4 {
        font-size: 13px;
        padding-bottom: 5px;
        padding-top: 5px;
        word-break: break-all;
    }
    .education-container {
        margin: 10px 0;
    }
    .description {
        padding: 10px 0 20px 0px;
    }
    .contact-title {
        margin-top: 0px;
    }
    .section-heading {
        padding: 5px 0 5px 10px;
        font-size: 18px;
    }

    .job-position {
        font-size: 16px;
        padding-top: 0;
    }
    .experience-item:first-child {
        padding-top: 0;
    }
    .experience-item {
        padding-bottom: 8px;
        padding-left: 0;
    }

    .reference-item {
        flex: 0 1 100%; /* Set width to 100% */
    }
    .reference-item h3 {
        font-size: 16px;
    }
    .reference-item span,
    .reference-item p,
    .reference-item a {
        font-size: 14px;
    }
}
@media screen and (max-width: 425px) {
    .contact-link,
    .education-item,
    .language-item,
    .hobbies-item,
    .skill-6 {
        font-size: 11px;
    }
    .left-side-6 {
        padding: 20px 10px;
    }
    .experience-list {
        padding: 10px 0 10px 5px;
    }
    .references {
        gap: 10px;
    }
    .experience-item {
        padding-bottom: 5px;
        padding-left: 0;
    }
    .right-side-6 {
        padding: 15px;
    }
    .description {
        padding: 5px 0 20px 0px;
    }
    .company-info {
        font-size: 13px;
    }
}
