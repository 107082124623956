.react-datepicker-wrapper {
    width: 100%;
}
.step-progress-bar {
    width: 100%;
    background: #eaeaea;
    height: 5px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.step-progress-bar-item {
    background: var(--main-color);
    height: 100%;
    border-radius: 4px;
}

.step-btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    gap: 20px;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

.upload-btn {
    border: 1px dashed #e5e7eb;
    color: var(--second-color);
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    font-weight: bold;
    height: 60px;
}
.upload-btn span {
    font-size: 25px;
}
.upload-btn-wrapper input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    height: 100%;
}
.user-img-items img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.user-img-items {
    width: 120px;
    display: flex;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #e5e7eb;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
/* =============== Choose Template Page  +++++++++++++++++++ */
.choose-template-wrapper {
    padding-top: 30px;
}
.select-category {
    padding-bottom: 30px;
    width: 500px;
}
.select-category label {
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 10px;
}
.choose-template-title {
    font-size: 40px;
    margin-bottom: 50px;
    color: #000;
    font-weight: 700;
}
.choose-template-item {
    width: 100%;
    box-shadow: 4px 4px 10px 4px #d5e1fa;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    border-radius: 6px;
    transition: 0.4s;
    border: 1px #fff solid;
}
.choose-template-item:hover {
    border: 1px #ffb317 solid;
    transform: scale(1.02);
}
.choose-template-item:hover::before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-repeat: no-repeat;
}
.choose-template-item img {
    width: 100%;
}

/* ====================== Resume Template 5 ================== */

.outer-box {
    padding: 30px;
}
.resume {
    width: 900px;
    height: fit-content;
    padding: 20px 0px 0px 20px;
    margin: 0 auto;
    background-color: #ffffff;
    display: grid;
    box-shadow: 4px 4px 15px 4px #98949475;
    color: #000;
    border: 1px solid #ddd;
}
.resume-top {
    display: flex;
    justify-content: space-between;
}
.resume-top .resume-left {
    width: 30%;
    float: left;
    background-color: #484848;
    border: 0px;
    border-radius: 500px 500px 0 0;
}
.resume-img {
    text-align: center;
    padding: 20px;
    display: flex;
    justify-content: center;
}
.resume-img img {
    width: 150px;
    border: 2px solid #fff;
    border-radius: 50%;
}
.resume-left .resume-skill {
    font-size: 14px;
}
.resume-left .resume-language {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 14px;
}
.resume-left .resume-language li {
    float: left;
    padding: 0px 10px 0px 0px;
}
.resume-left .resume-interest {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 14px;
}
/* .resume-left .resume-interest li {
    float: left;
    padding: 0px 10px 10px 0px;
} */
.resume-left .degree-place {
    padding-bottom: 10px;
}
.resume-interest-item {
    display: flex;
}
.resume-top .resume-right {
    float: right;
    padding: 30px 30px 0px 30px;
    width: 70%;
    position: relative;
}
.resume-top .resume-right .resume-name {
    color: #d19855;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
}

.resume-top .resume-right .resume-position {
    color: #000000;
    letter-spacing: 2px;
    padding-bottom: 14px;
    padding-top: 10px;
    font-size: larger;
}
.resume-right p {
    text-align: justify;
    font-size: 14px;
}
.resume-bottom .resume-left {
    width: 30%;
    float: left;
    background-color: #484848;
    height: 100%;
    padding: 0px 20px 20px 20px;
}
.resume-left .resume-phone {
    padding-bottom: 10px;
    font-size: 14px;
}

.resume-left .resume-email {
    padding-bottom: 10px;
    font-size: 14px;
}
.resume-left .resume-address {
    font-size: 14px;
}
.resume-address p {
    line-height: 20px;
    color: #fff;
}
.resume-left .resume-degree {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    line-height: 10px;
    font-size: 14px;
}
.resume-left .resume-degree li {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.resume-bottom .resume-right {
    width: 70%;
    float: left;
    padding: 0px;
    margin: 0px;
    position: relative;
}
.resume-content {
    color: #fff;
    padding: 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;
}
.resume-content h3 {
    font-weight: 600;
}
.resume-head {
    color: #d19855;
    font-size: 17px;
    font-weight: 600;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.resume-right .resume-head {
    background-color: #484848;
    padding: 7px 100px 7px 30px;
    width: fit-content;
    text-transform: uppercase;
    border-radius: 0px 50px 50px 0px;
}
.resume-right .resume-experience {
    padding: 5px 10px 10px 30px;
}
.resume-experience li {
    padding: 0px 10px 10px 0px;
    line-height: 5px;
}
.resume-experience .job-description {
    line-height: 140%;
    text-align: justify;
}
.resume-right .job {
    color: black;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.resume-reference {
    display: flex;
}
.resume-right .ref {
    color: black;
    padding: 0px 0px 0px 30px;
    font-size: 14px;
}
.resume-right .ref span {
    font-weight: bolder;
}

.resume-right ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.resume-right ul li {
    position: relative;
    margin-bottom: 15px;
}
/* pdf 3 */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-body-3 {
    margin: 0 auto;
    min-height: 100vh;
    color: #000000;
    position: relative;
    max-width: 790px;
}
.main-body-3-print {
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0px 30px 0 0;
    overflow-x: hidden;
}

@media print {
    @page {
        size: A4;
        margin: 0;
    }

    #printarea {
        width: 100%;
    }
}

.upper-container-3 {
    position: relative;
    background-color: #ffffff;
    color: #000000;
    padding: 0px 20px 0px 30px;
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 20px;
}
.upper-head-3 {
    font-size: 42px;
    font-weight: 400;
    color: #ff5280;
}

.image-container-3 {
    position: relative;
    width: 300px;
    height: 140px;
    text-align: end;
    background-color: #ff5280;
    display: flex;
    justify-content: center;
}
.image-container-3 img {
    width: 180px;
    height: 180px;
    position: relative;
    z-index: 1;
    object-fit: cover;
    margin-top: 40px;
    border: 7px solid #ffffff;
}
.image-container-3::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.head-container-3 {
    position: relative;
    background-color: #d9d9d9;
    width: 80px;
    height: 50px;
    display: flex;
    align-items: center;
}
.head-container-3::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    margin-left: -30px;
}

.job-3 {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
}
.job-3-description {
    text-align: justify;
    padding-bottom: 20px;
    line-height: 140%;
}

.job-3:last-child .job-3-description {
    padding-bottom: 0;
}
.upper-designation {
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 2px;
    text-align: left;
    padding: 10px 0;
}
.upper-description {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0px 10px 0;
    text-align: justify;
    line-height: 140%;
}
.lower-container-3 {
    background: #ffffff;
    display: grid;
    grid-template-columns: 72% 28%;
    margin-top: 30px;
}
.experience-3,
.references-3 {
    font-weight: 700;
    color: #ff5280;
    text-transform: uppercase;
    padding-left: 30px;
}
.left-container-3 {
    position: relative;
    background: #ffffff;
    padding-bottom: 30px;
    margin-right: 30px;
}
.experience-ul {
    margin: 20px 0;
    padding-left: 30px;
}
.right-padding-3 {
    padding: 0px 30px 0px 30px;
}
.reference-inner-3 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 30px;
    font-size: 14px;
}
.skill-text {
    font-size: 14px;
}
.all-text {
    font-size: 20px;
}
.reference-content {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
}
.resume-7 {
    display: grid;
    grid-template-columns: 70% 30%;
}
.resume-7 .job-7 {
    margin-bottom: 20px;
}
.qr-image-7 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.qrcode-resume-8 {
    position: relative;
    top: -25px;
}
.qrcode-resume-8 .qr-canvas {
    width: 100px !important;
    height: 100px !important;
}
.resume-template-8 h1 {
    font-size: 30px !important ;
}
img.resume-8-profile {
    padding-left: 30px;
}
.qr-image-5 {
    position: absolute;
    right: 30px;
    top: 10px;
}
.resume {
    padding: 20px 0px 0px 10px;
}
.qr-image-5 canvas {
    width: 80px !important;
    height: 80px !important;
}
.education-9-text h4{
    font-size: 14px;
}

@media (max-width: 992px) {
    .resume-7 {
        grid-template-columns: 60% 40%;
    }
    .right-resume-content, .resume-contact-text ul li a{
        font-size: 14px;
    }
    .phoneNumbertitle, .emailtitle {
    margin-bottom: 5px;
}
    .phoneNumbertitle-degree{
    font-size: 14px;
}
.resume-10 .right-resume-content{
    word-break: break-all;
}
    .references-10-grid {
    display: grid;
    grid-template-columns: 100%;
    
}
    .resume-left-7-head {
        padding: 20px 20px 20px 20px !important;
    }
    .resume-template-7-ex {
        padding: 30px 20px 30px 0px !important;
    }
    img.resume-8-profile {
        padding: 0 10px;
        width: 100%;
    }
    .ref-7 {
        flex: 100% !important;
        word-break: break-all;
    }
    .right-side-7 {
        word-break: break-all;
    }
    .resume-right-7 {
        padding: 10px !important;
        word-break: break-all;
    }
    .qr-image-5 {
        position: absolute;
        right: 20px;
        top: 0px;
    }
    .resume-7-des {
        text-align: inherit !important;
    }
    .resume-experience .job-description {
        text-align: inherit;
    }
    .resume-left .resume-degree {
        line-height: inherit;
    }
    .resume-left .degree-place {
        word-break: break-all;
    }
    .qr-image-5 canvas {
        width: 60px !important;
        height: 60px !important;
    }
    .resume-top .resume-right .resume-position {
        font-size: 14px;
        width: 65%;
    }
    .resume-top .resume-right .resume-name {
        font-size: 16px;
    }
    .resume {
        width: 100%;
    }
    .resume-top .resume-right {
        float: right;
        padding: 10px 15px 0px 15px;
        width: 70%;
    }
    .resume-right p {
        text-align: inherit;
        line-height: 130%;
        word-break: break-all;
    }
    .resume-top .resume-right .resume-position {
        padding-bottom: 5px;
    }
    .resume-name-7 {
        font-size: 25px !important;
    }
    .resume-name-7 span {
        font-size: 25px !important;
    }
    .resume-7 p {
        text-align: inherit;
    }
    .resume-7 h2 {
        font-size: 16px !important;
    }
    .main-body-7 {
        transform: scale(0.95);
    }
    .resume-template-8 h1 span {
        font-size: 30px !important ;
    }
    .resume-template-8 p {
        text-align: inherit !important;
    }
    .qrcode-resume-8 .qr-canvas {
        width: 50px !important;
        height: 50px !important;
    }
    .resume-template-8 h1 {
        font-size: 20px !important;
    }
    .resume-template-8 h1 span {
        font-size: 20px !important;
        margin-right: 7px !important;
    }
    .qrcode-resume-8 {
        position: absolute;
        top: -18px;
        right: 10px;
    }
    .resume-template-wrap {
        transform: scale(0.95);
    }
    .resume-template-wrap p {
        font-size: 12px !important;
    }
    .resume-template-8-head {
        grid-template-columns: 35% 65% !important;
        padding: 0 !important;
    }
    .resume-template-8-head-righ {
        padding: 10px 10px 0 10px !important;
    }
    .resume-template-8-bottom {
        grid-template-columns: 35% 65% !important;
    }
    .resume-template-8-bottom-left {
        padding: 0 10px 10px 10px !important ;
        word-break: break-all;
    }
    .resume-template-8-bottom-left h2{
        font-size: 16px!important;
    }
    .resume-template-8-bottom-right {
        padding: 10px 10px 0px 10px !important;
        word-break: break-all;
    }
    .resume-template-8-bottom-right h2 {
        font-size: 16px !important;
    }
    .resume-bottom .resume-left {
        padding: 0 10px 0 10px;
    }
    .resume-right .ref {
        color: black;
        padding: 0px 0px 0px 15px;
        font-size: 14px;
    }
    .resume-4-degree {
        font-size: 14px;
    }
}
/* For small devices (tablets, 768px and below) */
@media (max-width: 768px) {
    .upper-container-3 {
        padding: 0px 15px;
        gap: 10px;
    }
    .resume-name-1 .qr-image {
        align-items: start;
    }
    .main-body-3-print {
        transform: scale(0.95);
        padding: 0;
    }
    .upper-description {
        text-align: inherit;
    }
    .image-container-3 {
        position: relative;
        width: 100%;

        text-align: end;
        background-color: #ff5280;
        display: flex;
        justify-content: center;
    }
    .image-container-3::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
    .experience-3,
    .references-3 {
        padding-left: 15px;
    }
    .left-container-3 {
        margin-right: 25px;
    }
    .experience-ul {
        margin: 20px 0;
        padding-left: 15px;
    }
    .right-container-margin {
        margin-right: 5px;
    }
    .reference-inner-3 {
        padding: 10px 0 0px 15px;
    }
    .resume-right .job {
        gap: 0px;
    }
    .resume-right ul li {
        margin-bottom: 0px;
    }
    .outer-box {
        padding: 0px;
        /* margin: 10px; */
        transform: scale(0.95);
    }
    .resume-right .resume-experience {
        padding: 5px 10px 10px 15px;
    }
    .resume-left .resume-email {
        word-break: break-all;
    }
    .resume-top .resume-left {
        width: 35%;
    }
    .resume-top .resume-right {
        width: 65%;
    }
    .resume-bottom .resume-left {
        width: 35%;
    }
    .resume-bottom .resume-right {
        width: 65%;
    }
    .resume-right .resume-head {
        padding: 7px 30px 7px 15px;
    }
}
@media (max-width: 576px) {
    .upper-container-3 {
        grid-template-columns: 60% 40%;
        /* padding: 0px 5px; */
        gap: 5px;
    }
    .upper-head-3 {
        font-size: 28px;
    }
    .upper-designation {
        font-weight: 400;
        font-size: 16px;
        padding: 5px 0;
    }
   .image-container-3 img {
    width: 100px;
    height: 100px;
    position: relative;
    z-index: 1;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: 81px;
    border: 5px solid #ffffff;
}
    .upper-description {
        font-size: 14px;
        padding: 5px 0;
    }
    .lower-container-3 {
        grid-template-columns: 62% 38%;
        margin-top: 10px;
    }
    .experience-ul {
        font-size: 14px;
    }
    .left-container-3 {
        margin-right: 15px;
    }
    .right-padding-3 {
        padding: 0px 15px;
    }
    .right-container-margin {
        margin-right: 5px;
    }
    .skill-text {
        font-size: 12px;
    }
    .all-text {
        font-size: 18px;
    }

    .reference-content {
        flex: 0 1 100%;
        margin-bottom: 10px;
    }
    .job-3-description {
        text-align: inherit;
    }
    .upper-head-3 {
        font-size: 20px;
    }

    .right-padding-3 {
        padding: 0px 10px;
        word-break: break-all;
    }
    .right-padding-3 h2 {
        font-size: 14px !important;
    }
}
@media (max-width: 424px) {
    .upper-container-3 {
        /* grid-template-columns: 55% 45%;  */
        padding: 0px 10px;
        gap: 5px;
    }

    .image-container-3 {
        position: relative;
        width: 200px;
        text-align: end;
        background-color: #ff5280;
        display: flex;
        justify-content: center;
    }

    .experience-3,
    .references-3 {
        padding-left: 10px;
    }
    .head-container-3 {
        width: 60px;
    }
}
