@media screen and (max-width: 1500px) {
    .vcard-roadmap-content-item:nth-child(2)::after {
        left: -15%;
    }
    .smart-card-gengetor .purple-filter {
        display: none;
    }
    .card-option .purple-filter {
        display: none;
    }
    .vcard-roadmap-content-item:nth-child(2)::before {
        left: -10%;
    }
}
@media screen and (max-width: 1400px) {
    .customer-header {
        width: 55%;
    }
    .how-it-work-item {
        height: 250px;
    }
    .vcard-roadmap-content-item:nth-child(2)::before {
        left: -10%;
    }
    .vcard-roadmap-content-item:nth-child(2)::after {
        left: -16%;
    }
    .select-qr-item {
        height: 100px;
    }
}
@media screen and (max-width: 1200px) {
    .sidebar {
        height: fit-content;
        min-height: fit-content;
        width: 100%;
        position: relative;
        top: 0;
        margin-bottom: 30px;
    }

    .dashboard-content {
        height: 100%;
        width: 100%;
        min-height: fit-content;
        padding-left: 0px;
    }
    .qr-sidebar-left {
        position: relative;
        width: 100%;
        padding: 20px 25px 25px 25px;
    }
    .faq-wrapper {
        display: grid;
        grid-template-columns: 100%;
        gap: 20px;
    }

    .main-filter {
        display: none;
    }
    .how-it-work-item {
        height: 250px;
    }
    .customer-header {
        width: 63%;
    }
    .pricing-card-list-qr ul li p {
        font-size: 13px;
        color: #162b60;
    }
    .pricing-card-list-qr ul li {
        gap: 5px;
    }
    .pricing-chard-item {
        padding: 35px 15px 50px 15px;
    }
}

@media screen and (max-width: 992px) {
    .qr-show {
        margin-top: 30px;
    }
    .appointment-btns {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 15px;
        margin-top: 0 !important;
    }
    .appointment-btns .submit-form {
        margin-top: 10px;
    }
    .appointment-btns button.submit-form {
        margin-bottom: 5px;
    }
    .appointment-btns .qr-nav {
        width: 100%;
    }
    .appointment-btns .qr-nav .qr-button {
        width: 100%;
    }
    .qr-input-item-wrapper {
        margin-bottom: 25px;
    }
    .qr-input-item-wrapper .qr-input-items {
        gap: 15px;
        flex-direction: column;
    }
    .qr-input-list-items {
        gap: 15px;
    }

    .how-it-work-item {
        margin-bottom: 30px;
    }
    .qr-input-fleid {
        padding: 70px 0 70px 0;
    }
    .vcard-roadmap-content-item:nth-child(2)::after {
        display: none;
    }
    .vcard-roadmap-content-items {
        gap: 20px;
    }
    .vcard-roadmap-content-item:nth-child(2)::before {
        display: none;
    }
    .hero-content-item h1 {
        font-size: 50px;
        margin-bottom: 20px;
    }
    .hero-content {
        position: relative;
        transform: none;
        width: 100%;
        top: 0;
        left: 0;
    }
    .card-option-nfc-item {
        height: auto;
    }
    .qr-input-item-wrapper {
        padding: 0px;
    }
    .qr-input-content h4 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .qr-code-item {
        padding: 40px 0px 0px 0px;
    }
    .hero-wrapper {
        margin-top: 50px;
    }
    .card-option-item {
        margin-bottom: 40px;
    }
    .pricing-card-wrapper-dasktop {
        display: none;
    }
    .pricing-card-wrapper-mobile {
        display: block;
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .nfc-wrapper {
        margin-top: 0;
    }
    .customer-btn {
        padding: 0 0;
    }
    .security-header {
        width: 100%;
    }
    .security-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }
    .how-it-work-content {
        margin-top: 50px;
    }
    .shipping-list-item {
        grid-template-columns: 14% 82%;
    }
    .compnay-name-info {
        padding-right: 0px;
    }
}
@media screen and (max-width: 768px) {
    .hero-section {
        height: 56vh;
    }
    .select-qr-item {
        height: 100px;
    }
    .select-qr-title {
        padding-bottom: 25px;
    }
    .select-qr-img {
        padding-top: 15px;
    }
    .card-option-nfc-item {
        padding: 25px 29px;
        grid-template-columns: 14% 78%;
        gap: 34px;
    }
    .customer-item {
        width: 100%;
        padding: 0 30px;
    }
    .how-it-work {
        padding-bottom: 30px;
    }
    .security-content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .footer-item {
        margin-bottom: 35px;
    }
    .security-content > *:nth-child(6) {
        margin-bottom: 0;
    }
    .card-nfc-img {
        width: 70px;
    }
    .card-option-nfc-item {
        margin-bottom: 30px;
    }
    .card-option-nfc-content h3 {
        font-size: 20px;
    }
    .hero-content-item h1 {
        font-size: 35px;
    }
    .card-option-item {
        height: 100%;
    }
    .hero-content-item {
        width: 100%;
        padding: 0 15px;
    }
    .vcard-roadmap-content-items {
        grid-template-columns: 1fr;
    }
    .vcard-roadmap-content {
        padding-top: 20px;
    }
    .vcard-roadmap-content-items-2 {
        display: flex;
        flex-direction: column-reverse;
    }
    .logo img {
        width: 120px;
    }
    .card-option-content {
        margin-top: 25px;
    }
    .card-option-content p {
        margin-top: 16px;
    }
    .faq-wrapper h1 {
        font-size: 30px;
    }
    .compnay-info {
        border-right: 0;
        margin-bottom: 20px;
    }
    .upload-image-wrapper .swiper-horizontal {
        padding: 0 5px;
    }
    .how-it-work-content {
        margin-top: 40px;
    }
    .how-work-content h2 {
        font-size: 20px;
    }
    .dropzone-upload-img {
        margin-bottom: 20px;
    }
    .product-discount-item {
        margin-top: 20px;
    }
    .hero-content h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .hero-section {
        padding: 60px 0 60px 0;
    }
    button.hero-btn {
        margin-top: 30px;
    }
    .vCard-roadmap {
        padding-bottom: 50px;
        padding-top: 50px;
    }
    .vcard-roadmap-title h4 {
        font-size: 30px;
    }
    .vcard-roadmap-content-item h3 {
        font-size: 20px;
    }
    .home-header .logo a img {
        width: 120px;
    }
    .appointment-form-wrapper {
        padding-bottom: 60px;
    }
    .appointment-form-content {
        padding: 30px 15px 35px 15px;
    }
    .react-calendar button {
        padding: 10px 7px;
    }
    .appointment-time {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .appointment-time li {
        padding: 10px 12px;
        font-size: 13px;
    }
    .pricing-area-title h2 {
        font-size: 30px;
        font-weight: 700;
    }
    h1.section-title {
        font-size: 40px;
    }
    .customer-header {
        width: 100%;
    }
    .card-option-item {
        margin-bottom: 30px;
    }
    .customer-header {
        padding-bottom: 40px;
    }
    .customer-btn {
        padding: 0 0px;
    }
    .customer-btn .button-prev,
    .button-next {
        width: 20px;
        text-align: right;
        display: flex;
        justify-content: end;
    }
    .customer-btn .button-prev,
    .button-next img {
        width: 16px;
    }
    .partner-content {
        padding-top: 40px;
    }
    .browse-more-btn {
        font-size: 18px;
        padding: 10px 30px;
    }
    .faq-ques-item {
        grid-template-columns: 89% 8%;
        gap: 10px;
    }
    .footer {
        padding-bottom: 20px;
    }
    .pricing-chard-item {
        height: 780px;
    }
    .faq-pricing-wrapper {
        padding-top: 0;
    }
    .contact-us-wrapper {
        padding-top: 30px;
    }
    .contact-us-content {
        margin-top: 20px;
    }
    .qr-input-item-wrapper {
        gap: 30px;
    }

    .step-btn-item {
        gap: 10px;
        margin: 30px 0;
    }
    .qr-details .resume-qr-code-item {
        padding: 0 !important;
    }
    .priching-btn-item {
        gap: 10px;
        margin: 30px;
    }
    .priching-btn-item .custom-btn {
        font-size: 16px;
        padding: 8px 10px;
    }
    .mark-icon {
    bottom: -3px;
    right: -3px;
    padding: 3px;
    width: 16px;
    height: 16px;
}
}
@media screen and (max-width: 690px) {
    .my-preview-top {
        text-align: center;
    }
    .card-list.view-body-item {
        padding: 20px 25px;
    }
    .view-card-btn button {
        display: inline-grid;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        margin: 12px auto 0;
        box-shadow:
            0px 5px 14.1px 0.9px rgba(0, 0, 0, 0.24),
            0px 4px 6.6px 0.4px rgba(0, 0, 0, 0.16);
        cursor: pointer;
        transition: all 0.1s ease-out;
        position: relative;
        z-index: 998;
        overflow: hidden;
        padding: 10px;
    }
    .view-card-btn-appoint {
        width: 46px;
        height: 46px;
        padding: 10px;
        border-radius: 50%;
        margin-bottom: 10px;
    }
    .view-card-btn-appoint span {
        display: none;
    }
    .show-preview {
        padding-bottom: 120px;
    }

    .view-card-btn button span {
        display: none;
    }
    .view-btn-item {
        position: fixed;
        right: 20px;
        bottom: 40px;
        z-index: 11;
    }
    .view-btn-item .view-card-btn {
        position: relative;
        padding: 0;
        float: none;
        width: auto;
        margin-bottom: 10px;
    }
    .goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
        font-size: 9px;
    }
    .goog-te-gadget-simple {
        padding: 10px 5px !important;
    }
    .qr-code-item .with-logo {
        position: absolute;
        right: -16px;
        top: -15px;
        z-index: 1;
    }
    .client-say-item {
        height: 500px;
    }
    .qr-details {
        display: flex;
        gap: 10px;
    }
    .download-action .qr-downlod {
        padding: 6px 15px;
        font-size: 14px;
    }
    .mobile-scan-details {
        display: none;
    }
}
@media screen and (max-width: 640px) {
    .header-drop-menu {
        background: #fff;
        margin-top: 15px;
    }
    .qr-count {
        margin-bottom: 20px;
    }
    .custom-img-item {
        width: 100%;
        height: 198px;
        padding: 20px 10px;
    }
    .hero-content-item p {
        font-size: 16px;
        line-height: 140%;
    }
}
@media screen and (max-width: 578px) {
    .faq-question-wrapper {
        padding: 56px 0 40px 0;
    }
    .contact-us-header {
        margin-bottom: 20px;
    }
    .faq-content-wrapper {
        padding: 30px 0 0 0;
        margin-top: 0px;
    }
    .partner-img img {
        width: 115px;
    }
    .faq-content-wrapper h1 {
        margin-bottom: 20px !important;
    }
    .faq-content-item h3 {
        font-size: 22px;
    }
    .security-item h1 {
        font-size: 20px;
    }

    .view-information-wrraper {
        position: relative;
    }
    .payment-logo {
        margin-bottom: 40px;
    }
    .smart-card-gengetor-content {
        padding: 40px 30px;
    }
    h1.section-title {
        font-size: 30px;
    }
    .card-option-nfc-item {
        grid-template-columns: 100%;
        gap: 25px;
    }
    .customer-footer-item {
        gap: 20px;
        margin-top: 40px;
        flex-direction: column;
    }
    .client-say-wrapper {
        padding: 20px 0px;
        margin-top: 20px;
    }
    .client-btn {
        margin-top: 30px;
    }
    .faq-ques-item {
        grid-template-columns: 85% 12%;
        gap: 10px;
    }
}
@media screen and (max-width: 550px) {
    .qr-nav {
        display: block;
    }
    .business-contact-details {
        padding: 20px 30px 0 30px;
    }
    .compnay-name-info h3 {
        font-size: 20px;
    }
    .compnay-name-info p {
        font-size: 15px;
    }
    .scan p:first-child {
        font-size: 20px;
    }
    .scan {
        font-size: 15px;
    }
    .details a {
        font-size: 15px;
    }
    .compnay-info {
        border-right: 0;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .qr-nav {
        display: block;
    }

    .qr-show {
        margin-top: 30px;
    }

    a.qr-downlod:focus {
        color: #fff;
    }

    a.qr-downlod:hover {
        color: #fff;
        border: none;
        outline: none;
    }
    .sidebar-wrapper {
        padding: 35px 30px;
    }
    .image-up-label {
        display: flex;
        align-items: center;
        height: 100%;
        text-align: center;
        justify-content: center;
        padding-bottom: 15px;
    }
    .upload-image {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        flex-direction: column;
    }
    .start-item-list {
        margin-top: 14px;
    }
    .card-option-headers .section-title {
        font-size: 25px;
    }
}
@media screen and (max-width: 400px) {
    .form-box {
        padding: 25px;
    }
    .link-to-signup p {
        font-size: 15px;
    }
}
