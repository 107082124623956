.is-sticky.menu-nav {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 111;
    box-shadow: 0 3px 3px -2px rgba(27, 41, 75, 0.12),
        0 3px 4px 0 rgba(27, 41, 75, 0.12), 0 1px 8px 0 rgba(27, 41, 75, 0.12);
}
.header-menu {
    width: 100%;
    z-index: 111;
    height: 72px;
    background-color: #ffffff;
    padding: 16px 24px;
    box-shadow: 0 3px 3px -2px rgba(27, 41, 75, 0.12),
        0 3px 4px 0 rgba(27, 41, 75, 0.12), 0 1px 8px 0 rgba(27, 41, 75, 0.12);
}
.menu-nav {
    background: var(--white-color);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111;
    /* height: 72px; */
    background-color: #ffffff;
    padding: 16px 24px;
    box-shadow: 0 3px 3px -2px rgba(27, 41, 75, 0.12),
        0 3px 4px 0 rgba(27, 41, 75, 0.12), 0 1px 8px 0 rgba(27, 41, 75, 0.12);
}
.header {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.qr-sidebar-left {
    position: absolute;
    width: 260px;
    padding: 20px 25px 45px 25px;
    bottom: 0%;
    left: 0;
    color: #fff;
    background: #3c4245;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
}
.qr-sidebar-left h3 {
    padding-bottom: 15px;
}
.menu {
    display: flex;
    align-items: center;
    gap: 10px;
}
.login-wrapper{
    border-radius: 20px;
border: 1px solid #B5B5B5;
    padding: 50px 40px;
    width: 400px;
    background: #ffffff;
}
.login-header h1{
    font-size: 30px;
    font-weight: 700;
    color: #ffb317;
    padding: 15px 0;
    text-align: center;
}
.login-page{
    padding: 115px 0 70px 0;
}
.login-head-text {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px;
}
.login-head-text span a{
    color: #00B2D9;
}
.forget-text-color a{
color: #00B2D9;
}
.login-btn{
     color: #fff;
     padding: 7px 20px;
     width: 100%;
     background: #ffb317 !important;
     border-radius: 4px;
     transition: 0.4s;
}
.login-btn:hover{
     background: #b47903;
}
.forget-page {
    width: 400px;
    margin-top: 20px;
    text-align: center;
}
.menu-bar-btn{
    display: none;
}
.menu-close-btn{
    display: none;
}
@media (max-width: 1024px) {
    .menu {
        position: fixed;
        right: -100%;
        top: 0;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        padding: 50px 20px;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
    }

    .menu.active {
        right: 0;
    }

    .menu-bar-btn {
        display: block;
        font-size: 24px;
    }

    .menu ul {
        flex-direction: column;
        gap: 20px;
    }

    .menu-close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
        font-size: 28px;
    }
}
@media screen and (max-width: 768px) {
    .login-wrapper{
    border-radius: 10px;
border: 1px solid #B5B5B5;
    padding: 30px 20px;
    width: 100%;
    background: #ffffff;
}
.login-page{
    padding: 80px 0 60px 0;
}
    .login-container{
        width: 100%;
        margin: auto;
        padding: 10px;
    }
    .login-header h1{
        font-size: 24px;
    }
    .forget-page{
        width: 100%;
    }
}
@media screen and (max-width: 568px) {
    .menu-nav {
        padding: 10px 0;
    }
    .menu {
    gap: 3px;
}
    .home-header .menu ul {
        gap: 30px;
    }
    .menu ul .custom-btn {
        padding: 7px 30px;
        font-size: 14px;
    }
    .login-hight-item{
        min-height:100% !important;
    }
}
.logo-img img {
    width: 80%;
}
