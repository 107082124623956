/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Arial Narrow", Arial, sans-serif;
  } */

  .main-body-13 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
    overflow-x: hidden;
  }

  .qr-image-13 img {
    width: 100px;
  }
  .resume-gap-13 {
    margin-right: 20px;
    margin-top: 20px;
  }
  .resume-13 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    display: grid;
    grid-template-columns: 70% 30%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .resume-13 .left-side-13 {
    position: relative;
    padding: 20px;
    color: #000000;
    word-break: break-all;
  }
  .profile-text-13 {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .profile-img-13 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .profile-img-13 img {
    width: 170px;
    height: 170px;
    position: relative;
    z-index: 1;
    border: 2px solid #f97700;
    border-radius: 50%;
    object-fit: cover;
  }

  .resume-13 .right-side-13 {
    position: relative;
    padding: 0 20px 20px;
  }
  .sub-heading-13 {
    color: #f97700;
    text-align: left;
    font-size: 18px;
    padding: 15px 0 0 0;
    border-bottom: 2px solid #000000;
    text-transform: uppercase;
    padding-bottom: 6px;
    font-weight: 700;
  }
  .contact-details-13 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 4px 10px 10px 0;
    line-height: 140%;
  }
  .contact-heading-13 {
    margin-top: 10px;
  }

  .education-13-details-13 {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
  }
  .education-13 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .skill-13 {
    padding: 10px 0 10px 0px;
    font-size: 14px;
  }

  .skill-13 li {
    width: 100%;
  }
  .language-13 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
  }

  .language-13 li {
    box-sizing: border-box;
  }

  .interest-13 {
    list-style: none;
    margin: 0;
    position: relative;
    font-size: 16px;
  }

  .interest-13 li {
    padding: 5px 10px 0 0;
    box-sizing: border-box;
  }

  .resume-name-13 {
    color: #000000;
    font-size: 42px;
    font-weight: 400;
  }
  .first-name-13 {
    color: #000000;
    font-size: 42px;
    font-weight: 900;
    margin-right: 7px;
  }
  .resume-13-display {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    position: relative;
    margin-bottom: 10px;
  }
  .resume-13-display .image-container {
    width: 130px;
    height: 180px;
    background-color: #f97700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }


  .resume-designation-13 {
    font-weight: 400;
    line-height: 19px;
    color: #f97700;
    padding: 10px 0;
  }
  .resume-about-13 {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
  }
  .experience-13 {
    font-weight: 700;
    color: #f97700;
    border-bottom: 2px solid #000;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 18px;
  }

  .resume-experience-13 {
    position: relative;
    padding: 10px 0;
  }

  .job-13 {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
  }
  .job-13:last-child {
    border-bottom: none;
  }

  .job-13-year {
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
    color: #f97700;
  }

  .company-name-13 {
    padding: 5px 0;
  }
  .job-content-13 {
    /* padding-left: 25px; */
    box-sizing: border-box;
  }

  .job-13-position {
    padding: 5px 0;
    font-size: 16px;
    color: #f97700;
  }

  .job-13-description {
    padding-bottom: 20px;
    line-height: 140%;
  }

  .job-13:last-child .job-13-description {
    padding-bottom: 0;
  }

  .reference-13 {
    font-weight: 700;
    color: #f97700;
    padding-block: 10px;
    border-bottom: 2px solid #000;
    text-transform: uppercase;
    font-size: 18px;
  }
  .resume-reference-13 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0;
  }

  .ref-13 {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
  }
  .resume-right-13 .ref-13 {
    color: black;
    font-size: 14px;
  }
  .ref-13 p {
    line-height: 15px;
  }
  .resume-right-13 .ref-13 span {
    font-weight: bolder;
  }
  .ref-name-13 {
    padding: 4px 0 4px 0;
    font-size: 18px;
  }
  .ref-position-13,
  .ref-phone-13,
  .ref-email-13 {
    padding: 3px 0 3px 0;
  }

  .contact-link-13 {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-word;
  }

  .contact-link-13:hover {
    color: #000000;
    text-decoration: underline;
  }

    .resume-13-display .image-container  canvas{
    padding:10px;

  }
  .qr-image-13 {
    width: 80%;
    height: auto;
    object-fit: contain;
  }
  .image-container-bottom {
    width: 100%;
    height: 20px;
    background-color: #f97700;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  .interest-13-text {
    margin-bottom: 20px;
  }

  /* Responsive Design */

  @media screen and (max-width: 768px) {
    .profile-img-13 img {
      width: 23vw;
      height: 23vw;
    }
        .ref-13 {
      flex: 100% !important; 
    }
  .resume-13-display .image-container {
      width: 90px;
      height: 165px;
    }
    .resume-designation-13 {
      padding: 5px 0;
      font-size: 20px;
    }
    .first-name-13,
    .resume-name-13 {
      font-size: 32px;
    }
    .resume-about-13 {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 0;
    }
    .qr-image-13 img {
      width: 12vw;
      height: 12vw;
    }
    .resume-13 {
    transform: scale(0.95);
}
canvas.qr-image-13 {
    padding: 0;
    width: 60px !important;
    height: 60px !important;
}
.ref-13 p{
  display: flex;
  gap: 10px;
}
  }

  @media screen and (max-width: 525px) {
    .resume-13 .left-side-13 {
      padding: 20px 12px;
    }
    .resume-gap-13 {
      margin-right: 15px;
    }

    .resume-13 .right-side-13 {
      padding: 0 15px 15px;
    }
    .sub-heading-13,
    .ref-name-13 {
      font-size: 15px;
    }
    .job-13-position {
      font-size: 14px;
    }
    .company-name-13 {
      font-size: 13px;
      padding: 0;
    }
    .experience-13,
    .reference-13 {
      font-size: 16px;
    }

    .contact-link-13,
    .contact-details-13,
    .education-13-details-13,
    .skill-13,
    .language-13,
    .interest-13 {
      font-size: 13px;
    }
    .contact-details-13 {
      padding: 4px 10px 5px 0;
    }
    .contact-heading-13 {
      margin-top: 5px;
    }
    .resume-about-13,
    .job-13-description {
      font-size: 14px;
    }
    .degree-13,
    .contact-heading-13,
    .job-13-year,
    .ref-position-13,
    .ref-phone-13,
    .ref-email-13 {
      font-size: 14px;
    }

    .skill-13 li {
      width: 100%;
    }
    .resume-name-13,
    .first-name-13 {
      font-size: 26px;
    }
    .resume-designation-13 {
      font-size: 16px;
      padding: 5px 0;
    }
    .resume-about-13 {
      padding: 5px 0 5px 0;
    }
    .job-13-description {
      padding-bottom: 15px;
    }

    .qr-image-13 {
      display: flex;
      align-items: center;
    }

   .resume-13-display .image-container {
      width: 80px;
      height: 150px;
    }
    .resume-reference-13 {
      gap: 10px;
    }
    .language-13 li,
    .interest-13 li {
      padding: 5px 10px 0 0;
    }
    .image-container-bottom {
      height: 10px;
    }
    .resume-13-display {
      gap: 10px;
      margin-bottom: 5px;
      word-break: break-all;
    }
    .resume-experience-13 {
      padding: 10px 0 0 0;
    }
  }
  @media screen and (max-width: 425px) {
    .resume-13 .left-side-13 {
      padding: 20px 10px;
    }
    .resume-13 .right-side-13 {
      padding: 0 10px 20px;
    }
    .resume-13 {
      display: grid;
      grid-template-columns: 65% 35%;
    }
    .resume-designation-13 {
      font-size: 15px;
      padding: 0;
    }
    .resume-gap-13 {
      margin-right: 10px;
      margin-top: 15px;
    }
    .resume-about-13 {
      padding: 5px 0 10px 0;
    }
    .contact-details-13,
    .education-13-details-13,
    .skill-13,
    .language-13,
    .interest-13 {
      font-size: 11px;
    }
    .resume-about-13,
    .job-13-description {
      font-size: 13px;
    }
    .first-name-13 {
      margin-right: 8px;
    }
    .resume-name-13,
    .first-name-13 {
      font-size: 22px;
    }
    .skill-13 {
      padding: 10px 0 10px 0px;
    }
    .contact-link-13{
      font-size: 14px;
    }
  }
  @media screen and (max-width: 375px) {
    .resume-about-13,
    .job-13-description {
      font-size: 12px;
    }
    .resume-designation-13 {
      font-size: 14px;
    }
    .resume-name-13,
    .first-name-13 {
      font-size: 20px;
    }
  }
