/* *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial Narrow', Arial, sans-serif;
} */

.resume-wrap {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
    width: 100%;
}

/* resume right side css start from here */

.resume-14 {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 100%;
    min-height: auto;
    background: #ffffff;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.top-section-14 {
    color: #ffffff;
    background-color: #2e3e4e;
    text-align: center;
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 50px 0;
}
.profile-pic-14 {
    text-align: left;
}
.profile-pic-14 img {
    border-radius: 50%;
    border: 5px solid #ffffff;
    position: relative;
    left: 10.5%;
    margin-bottom: -220px;
    z-index: 1;
    width: 200px;
    height: 200px;
}

.resume-name-14 {
    font-size: 42px;
    font-weight: 400;
}
.first-name-14 {
    font-size: 44px;
    font-weight: 700;
    margin-right: 15px;
}
.resume-designation-14 {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 3px;
}
.resume-14-bottom {
    display: grid;
    grid-template-columns: 30% 70%;
    font-size: 15px;
}
.resume-14 .left-side-resume {
    background-color: #f1f1f1;
    position: relative;
    padding: 80px 20px 20px;
    text-align: center;
    word-break: break-all;
}
.left-resume-content-text-14 {
    padding-top: 10px;
    line-height: 140%;
    color: #000000;
}
.resume-contact {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}
.resume-contact-list-14 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.resume-contact-text-14 {
    margin-bottom: 20px;
}
.resume-contact-text-14 ul {
    list-style: none;
}
.resume-skill-14 {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    text-align: center;
}

.resume-header-left-14 {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
    text-transform: uppercase;
}
.phoneContainer-14,
.emailContainer-14 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.phoneNumbertitle-14,
.emailtitle-14 {
    margin-right: 5px;
    font-weight: 700;
}

.phoneNumber-14,
.emailAddress-14 {
    text-decoration: none;
    color: black;
}
.phoneNumber-14:hover,
.emailAddress-14:hover {
    color:#000;
    transition: color 0.3s ease-in-out;
}

.job-position-14 {
    font-weight: 700;
    font-size: 15px;
    margin: 8px 0 0 0;
}
.reference-gap {
    margin-bottom: 20px;
}

/* resume left side css ends here */

/* resume right side css start from here */

.resume-14 .right-side-resume {
    position: relative;
    background: #ffffff;
    line-height: 140%;
    padding: 40px;
}

.marginTop {
    margin-top: 20px;
}
.resume-description-14 {
    font-size: 16px;
    font-weight: 400;
}
.resume-description-p {
    font-size: 16px;
    font-weight: 400;
    padding: 0px 0 10px 0;
}
.resume-header-right-14 {
    font-weight: 700;
    padding-bottom: 8px;
    text-transform: uppercase;
    padding-top: 20px;
    border-bottom: 2px solid #000;
}

.company-date-14 {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.resume-description1 {
    font-size: 16px;
}
.resume-experience {
    padding: 10px 0 10px 10px;
}

.resume-contact-list-14 li h4 {
    margin-bottom: 7px;
}

/* resume right side css ends here */
@media screen and (max-width: 768px) {
    .top-section-14 {
        grid-template-columns: 35% 65%;
        padding: 30px 0;
    }
    .company-date-14 {
        flex-direction: column;
        gap: 0;
    }
    .left-resume-content-text-14 {
        padding-top: 80px;
    }
    .resume-14 {
        margin: 0;
        transform: scale(0.95);
    }
    .profile-pic-14 img {
        border-radius: 50%;
        border: 5px solid #ffffff;
        position: relative;
        left: 9.5%;
        margin-bottom: -122px;
    }
    .resume-contact {
        padding: 0 20px;
    }
    .top-section-14 .qr-image {
        width: 60px !important;
        height: 60px !important;
    }
    .profile-pic-14 {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile-pic-14 img {
        width: 100%;
        border: 2px solid #ffffff;
        height: 130px;
    }
    .resume-name-14,
    .first-name-14 {
        font-size: 22px;
        padding-bottom: 10px;
    }
    .resume-14 .right-side-resume {
        position: relative;
        background: #ffffff;
        line-height: 140%;
        padding: 20px 10px;
    }
    .resume-14-bottom {
        display: grid;
        grid-template-columns: 35% 65%;
        font-size: 15px;
    }
    .resume-14 .left-side-resume {
        padding: 10px 10px 10px;
    }
    .resume-header-left-14 {
        font-size: 15px;
    }
}

@media screen and (max-width: 576px) {
    .profile-pic-14 {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left-resume-content-text-14 {
        padding-top: 60px;
    }
    .top-section-14 {
        grid-template-columns: 28% 72%;
        padding: 30px 0;
    }
    .resume-contact {
        padding: 0 20px 0 0;
    }
    .profile-pic-14 img {
        width: 100%;
        height: 100px;
    }
}
