.main-body {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
}

/* resume right side css start from here */

.resume-10 {
    position: relative;
    width: 100%;
    max-width: 790px;
    height: 100%;
    min-height: auto;
    background: #ffffff;
    display: grid;
    grid-template-columns: 70% 30%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.top-section {
    margin-bottom: 30px;
}
.resume-10 .left-side-resume {
    position: relative;
    padding: 30px;
}
.left-resume-content {
    line-height: 140%;
}

.resume-name {
    font-size: 42px;
    font-weight: 400;
}
.first-name {
    font-size: 42px;
    font-weight: 700;
    margin-right: 5px;
}
.left-resume-content-section {
    border-bottom: 1px solid #b5b5b5;
}

.left-resume-content-section:last-of-type {
    border-bottom: none;
}
.resume-designation {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 2px;
    text-align: left;
    margin-top: 10px;
}
.resume-description {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0 20px 0;
}
.resume-description1 {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0 0px 0;
}
.resume-header {
    font-weight: 700;
    border-bottom: 2px solid #000000;
    padding-bottom: 5px;
}
.workplace {
    border-right: 1px solid #000000;
    padding-right: 10px;
}
.workplace-name {
    padding-left: 10px;
}
.job-position {
    font-weight: 700;
    font-size: 16px;
    margin: 5px 0 0 0;
}

.resume-experience {
    padding: 10px 0 10px 10px;
}
.references-10-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
}

.phoneContainer,
.emailContainer {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.phoneNumbertitle,
.emailtitle {
    margin-right: 5px;
}

.phoneNumber,
.emailAddress {
    text-decoration: none;
    color: black;
}
.phoneNumber:hover,
.emailAddress:hover {
    color: #ffb317;
}
/* resume left side css ends here */

/* resume right side css start from here */

.resume-10 .right-side-resume {
    position: relative;
    background: #f5f5f5;
}

.right-resume-content {
    position: relative;
    margin-top: -100px;
    padding: 30px;
    background-color: #989898;
}
.resume-10 .right-resume-content {
    position: relative;
    margin-top: -10px;
    padding: 30px;
    background-color: #989898;
}
.right-resume-image img {
    position: relative;
    width: 238px;
    height: 238px;
}
.resume-contact-text {
    margin-bottom: 20px;
}
.resume-contact-text ul {
    list-style: none;
}
.resume-contact-text ul li {
    margin: 10px 0;
    line-height: 140%;
}
.right-resume-content,
.resume-contact-text ul li a {
    color: #fff;
    word-break: break-all;
}
.right-resume-content,
.resume-contact-text ul li a:hover {
    color: #fff;
}
.resume-skill {
    list-style-type: none;
}

.resume-header-right {
    font-weight: 700;
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
}
.right-resume-content-text {
    padding-top: 60px;
}
.resume-10 .right-resume-content-text {
    padding-top: 0px;
}

/* resume right side css ends here */
