.pricing-area {
    background: #d6e7f3;
    position: relative;
    overflow: hidden;
}
.pricing-wrapper {
    padding: 65px 0 0px 0;
}

.pricing-wrapper .swiper {
    padding: 20px 20px;
}
.pricing-card-wrapper-mobile {
    display: none;
}
.pricing-card-wrapper-dasktop {
    padding-top: 50px;
}
.pricing-chard-item {
    padding: 35px 20px 30px 20px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #fff;
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 15px;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    height: 100%;
    background-repeat: no-repeat;
    z-index: 1;
}
.pricing-card-list-qr {
    width: 100%;
    margin-bottom: 20px;
}

.pricing-chard-item:hover {
    background-color: #162b60;
}
.pricing-chard-item:hover::before {
    background-image: url('../../public/img/home/pricing-bg.png');
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    content: '';
    z-index: -1;
    top: 0;
    width: 100%;
    height: 90%;
    right: 0;
}

.pricing-chard-item:hover h3 {
    color: var(--white-color);
}
.pricing-chard-item:hover h2 {
    color: var(--white-color);
}
.pricing-chard-item:hover .pricing-card-list-qr ul li p {
    color: var(--white-color);
}
.pricing-chard-item:hover .pricing-cart-list {
    color: var(--white-color);
}
.pricing-chard-item:hover .pricing-cart-list li {
    color: var(--white-color);
}
.pricing-chard-item:hover .pricing-card-list {
    color: var(--white-color);
}
.pricing-chard-item:hover .pricing-card-list-qr ul li .pricing-count {
    color: #162b60;
}
.pricing-chard-item:hover .pricing-cart-lis li span {
    color: var(--white-color);
}

.pricing-chard-item:hover .pricing-card-list.price h3 {
    color: var(--white-color);
}

.pricing-des ol li {
    margin-bottom: 10px;
}

.pricing-chard-item:hover .pricing-description p span strong {
    color: #fff;
}

.pricing-chard-item:hover .pricing-description p ul li,
.pricing-chard-item:hover .pricing-description p ol li {
    color: #fff;
}

.pricing-chard-item:hover .pricing-description p {
    color: #fff;
}
.pricing-chard-item:hover .pricing-card-list p{
      color: #fff;
}

.pricing-chard-item:hover::before {
    content: '';
    background-repeat: no-repeat;
}

.pricing-chard-item .most-popular-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    background: #ffb317;
    color: #fff;
}
.pricing-header {
    margin-bottom: 50px;
    text-align: center;
    position: absolute;
}
.pricing-header-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pricing-header h1 {
    color: #ffb317;
    font-size: 50px;
    font-weight: 700;
    line-height: 140%;
}
.pricing-header p {
    color: #fff;
}
.pricing-card-list h3 {
    font-size: 24px;
    font-weight: 700;
    color: #162b60;
    line-height: 140%;
}
.pricing-card-list h2 {
    font-size: 28px;
    font-weight: 800;
    color: #162b60;
    line-height: 140%;
}
.pricing-card-list p {
    font-size: 14px;
    color: #162b60;
}
.pricing-card-list .package-btn {
    padding: 5px 30px;
    border-radius: 50px;
    background: #898989;
    color: #fff;
    font-size: 16px;
    margin-top: 10px;
}
.pricing-card-list.price h3 {
    color: #162b60;
}

.pricing-card-list-qr ul li {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 56% 40%;
}
.pricing-card-list-qr ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.pricing-card-list-qr ul li .pricing-count {
    padding: 5px 10px;
    background: #f5f5f5;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
}

.pricing-card-list-qr ul li p {
    font-size: 15px;
    color: #162b60;
}
.priching-input .form-select {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
}

.select-pricing-item {
    display: flex;
    width: 100%;
    align-items: start;
    gap: 20px;
    justify-content: start;
}
.select-pricing-item .priching-input {
    text-align: start;
    width: 100%;
}
.select-pricing-item label {
    color: #fff;
    font-size: 18px;
}
.select-pricing-item select {
    width: 100%;
    margin-top: 10px;
}
/* .pricing-card-wrapper{
    padding-top: 60px;
} */

.pricing-area-title {
    text-align: center;
}

.pricing-area-title h2 {
    font-size: 48px;
    font-weight: 700;
    padding-bottom: 7px;
}
.pricing-area-title p {
    color: #162b60;
}

.pricing-area-title h2 span {
    color: var(--main-color);
}
.pricing-card-wrapper .swiper {
    padding: 50px 25px;
}
.pricing-card-wrapper .swiper-slide {
    height: auto;
}
.pricing-cart-list {
    padding-top: 15px;
    color: #162b60;
}
.pricing-cart-list ul li {
    position: relative;
    padding-left: 25px;
    font-size: 16px;
    color: #162b60;
}
.pricing-cart-list ul li::before {
    content: '';
    position: absolute;
    background-image: url('../../public/img/home/price-icon.svg');
    color: red;
    width: 50px;
    left: 0;
    top: 3px;
    height: 20px;
    background-repeat: no-repeat;
}
.pricing-chard-item:hover .pricing-cart-list ul li::before {
    content: '';
    position: absolute;
    background-image: url('../../public/img/home/price-icon-hover.svg');
    color: red;
    width: 50px;
    left: 0;
    top: 3px;
    height: 20px;
    background-repeat: no-repeat;
}
.pricing-cart-list .pricing-cart-lis li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
    font-size: 15px;
}
.pricing-cart-lis li span {
    font-size: 18px;
    line-height: 150%;
    color: var(--main-color);
    padding-top: 4px;
}
.pricing-cart-lis li p {
    color: #162b60;
    font-size: 14px;
}
/* {============== Pricing FAQ ===================} */
.faq-pricing-wrapper {
    padding-bottom: 80px;
}
.pricing-faq-header h1 {
    font-size: 50px;
    font-weight: 700;
    color: #000;
    line-height: 140%;
    text-align: center;
    margin-bottom: 20px;
}
.pricing-faq-content {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #b5b5b5;
    background: #fff;
    box-shadow: 1px 6px 25px -5px #f3e8ff, 1px 6px 5px -6px #f3e8ff;
}
.pricing-faq-content-header {
    padding: 15px 20px;
    background: #898989;
    color: #fff;
}
.pricing-faq-content-item {
    padding: 20px 20px 20px 20px;
}
.styles_faq-row-wrapper__3vA1D {
    background-color: none !important;
}

.row-title-text {
    color: #000;
    position: relative;
    margin: 0;
    display: block;
    width: 100%;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
}

.pricing-faq-content-header h4 {
    font-size: 25px;
    font-weight: 600;
}
.step-progress-bar {
    width: 100%;
    background: #eaeaea;
    height: 5px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.step-progress-bar-item {
    background: var(--main-color);
    height: 100%;
    border-radius: 4px;
}

.step-btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    gap: 20px;
}
.step-btn-item .custom-btn {
    width: fit-content;
}
.resume-step-btn-item .custom-btn {
    width:150px;
}

.shipping-list-item {
    display: grid;
    align-items: start;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    /* justify-content: center; */
    grid-template-columns: 7% 90%;
}

.shipping-icon {
    width: 35px;
    height: 35px;
    background: #616568;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
}

.shipping-icon img {
    width: 100%;
    /* padding: 10px; */
}

.shipping-list-wrapper {
    margin-top: 20px;
}
.loading-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
}

.lds-ripple,
.lds-ripple div {
    box-sizing: border-box;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid currentColor;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 8px;
        height: 8px;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 8px;
        height: 8px;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 8px;
        height: 8px;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
        opacity: 0;
    }
}

@media screen and (max-width: 992px) {
    .pricing-wrapper {
        padding: 60px 0 60px 0;
    }
    .pricing-header h1 {
        font-size: 30px;
    }
    .pricing-card-list h3 {
        font-size: 25px;
    }
    .pricing-faq-header h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .pricing-faq-content-header h4 {
        font-size: 20px;
        font-weight: 600;
    }
    .row-title-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 576px) {
    .pricing-header {
        margin-bottom: 30px;
    }
    .pricing-wrapper {
        padding: 60px 0 30px 0;
    }
    .faq-pricing-wrapper {
        padding-bottom: 40px;
    }
}
.pricing-des {
    text-align: left;
    margin-top: 20px;
    font-size: 14px;
}

.pricing-description {
    width: 100%;
}
.pricing-des ul li {
    margin-bottom: 10px;
}
.priching-cart-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pricing-des ul {
    margin-top: 10px;
    color: #686868;
}
.pricing-des p strong {
    color: #686868;
}
.back:hover {
    color: #ffb317;
}
