/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Arial Narrow", Arial, sans-serif;
  } */

.main-body-9 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
    overflow-x: hidden;
}

.qr-image-9 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.qr-image-9 img {
    width: 100px;
    height: auto;
}

.resume-gap-9 {
    margin-right: 20px;
    margin-top: 20px;
}
.resume-9 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    display: grid;
    grid-template-columns: 65% 35%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.resume-9 .left-side-9 {
    position: relative;
    padding: 0 20px 20px;
    color: #000000;
    word-break: break-all;
    border-left: 1px solid #00b2d9;
}
.profile-text-9 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.profile-img-9 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.profile-img-9 img {
    width: 130px;
    height: 130px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.resume-9 .right-side-9 {
    position: relative;
    padding: 0 20px 20px;
}
.sub-heading-9 {
    color: #fff;
    font-size: 16px;
    padding: 15px 0;
    text-transform: uppercase;
    background: #00b2d9;
    border-radius: 10px 0 0 0;
    padding-bottom: 6px;
    font-weight: 600;
    padding: 8px 0 10px 20px;
    margin: 15px -20px 10px 0;
}
.contact-9 {
    padding-left: 20px;
}
.contact-details-9 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    /* padding: 4px 10px 10px 0; */
    line-height: 140%;
}
.contact-heading-9 {
    margin-top: 10px;
}

.education-9-details-9 {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    /* padding: 3px 0 0 0; */
}
.education-9 {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 20px;
}

.skill-9 {
    list-style: none;
    padding: 0px 0 10px 20px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
}

.skill-9 li {
    width: 50%;
    /* padding: 5px 0; */
}

.skill-9 li:nth-child(odd) {
    float: right;
}

.skill-9 li:nth-child(even) {
    float: left;
}

.language-9 {
    list-style: none;
    margin: 0;
    padding: 0 0 10px 20px;
    position: relative;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
}

.language-9 li {
    flex: 0 1 calc(50% - 10px);
    padding: 10px 10px 0 0;
    box-sizing: border-box;
}

.interest-9 {
    list-style: none;
    margin: 0;
    padding: 0 0 10px 20px;
    position: relative;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
}

.interest-9 li {
    flex: 0 1 calc(50% - 10px);
    padding: 10px 10px 0 0;
    box-sizing: border-box;
}

.resume-name-9 {
    color: #00b2d9;
    font-size: 42px;
    font-weight: 400;
}
.first-name-9 {
    color: #00b2d9;
    font-size: 42px;
    font-weight: 900;
    margin-right: 7px;
}
.resume-9-display {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    position: relative;
    margin-bottom: 10px;
}

.resume-designation-9 {
    font-weight: 400;
    line-height: 19px;
    padding: 10px 0;
    font-size: 20px;
}
.resume-about-9 {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
}
.experience-9 {
    font-weight: 700;
    color: #ffffff;
    padding: 8px 0 10px 20px;
    margin: 10px 0 10px -20px;
    background: #00b2d9;
    font-size: 18px;
    border-radius: 0 10px 0 0;
}

.resume-experience-9 {
    position: relative;
    padding: 10px 0;
}

.job-9 {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b5b5b5;
    margin-bottom: 10px;
}
.job-9:last-child {
    border-bottom: none;
}

.job-9-year {
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
}

.company-name-9 {
    padding: 5px 0;
}
.job-content-9 {
    /* padding-left: 25px; */
    box-sizing: border-box;
}

.job-9-position {
    padding: 5px 0;
    font-size: 16px;
}

.job-9-description {
    padding-bottom: 20px;
    line-height: 140%;
}

.job-9:last-child .job-9-description {
    padding-bottom: 0;
}

.reference-9 {
    font-weight: 700;
    color: #ffffff;
    padding: 8px 0 10px 20px;
    margin: 10px 0 10px -20px;
    background: #00b2d9;
    font-size: 18px;
    border-radius: 0 10px 0 0;
}
.resume-reference-9 {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    gap: 20px;
    padding: 10px 0 0px 0;
}

.ref-9 {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
}
.resume-right-9 .ref-9 {
    color: black;
    font-size: 14px;
}
.ref-9 p {
    line-height: 15px;
}
.resume-right-9 .ref-9 span {
    font-weight: bolder;
}
.ref-name-9 {
    padding: 4px 0 4px 0;
    font-size: 18px;
}
.ref-position-9,
.ref-phone-9,
.ref-email-9 {
    padding: 3px 0 3px 0;
}

.contact-link-9 {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-word;
}

.contact-link-9:hover {
    color: #000000;
    text-decoration: underline;
}
.image-container-9 {
    width: 150px;
    height: 200px;
    background-color: #00b2d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.interest-9-text {
    margin-bottom: 20px;
}

/* Responsive Design */

@media screen and (max-width: 768px) {
       .profile-img-9 img {
        width: 18vw;
        height: 18vw;
    }
    .main-body-9 {
        transform: scale(0.95);
    }
    .resume-9 .right-side-9 {
        word-break: break-all;
    }
    .resume-9 .left-side-9 {
        padding: 0 20px 30px 20px;
    }
    .qr-image-9 img {
        width: 15vw;
        height: 15vw;
    }
    .resume-9 {
        grid-template-columns: 65% 35%;
    }
    .language-9,
    .interest-9 {
        display: inherit;
    }
    .references-10-grid {
        display: grid;
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 576px) {
    .resume-9 .left-side-9 {
        padding: 0 12px 20px 12px;
    }
    .resume-gap-9 {
        margin-right: 15px;
        margin-top: 0;
    }

    .resume-9 .right-side-9 {
        padding: 15px;
    }
    /* .job-content-9 {
      padding-left: 15px;
    } */
    .sub-heading-9,
    .job-9-position,
    .ref-name-9 {
        font-size: 14px;
    }
    .sub-heading-9 {
        padding: 8px 0 8px 10px;
    }
    .company-name-9 {
        font-size: 13px;
        padding: 0;
    }
    .experience-9,
    .reference-9 {
        font-size: 16px;
        padding: 8px 0 8px 20px;
    }
    .experience-9 {
        margin: 5px 0 5px -20px;
    }
    .interest-9-text {
        margin-bottom: 10px;
    }

    .contact-link-9,
    .contact-details-9,
    .education-9-details-9,
    .skill-9,
    .language-9,
    .interest-9 {
        font-size: 13px;
    }
    .contact-details-9 {
        padding: 4px 10px 5px 0;
    }
    .contact-heading-9 {
        margin-top: 5px;
    }

    .degree-9,
    .contact-heading-9,
    .job-9-year,
    .ref-position-9,
    .ref-phone-9,
    .ref-email-9 {
        font-size: 13px;
    }

    .skill-9 li {
        width: 100%;
        padding: 5px 0;
    }
    .resume-name-9,
    .first-name-9 {
        font-size: 22px;
    }
    .resume-designation-9 {
        font-size: 16px;
        padding: 5px 0 5px 0;
    }
    .resume-about-9 {
        padding: 5px 0 5px 0;
    }
    .job-9-description {
        padding-bottom: 15px;
    }
    .ref-9 {
        flex: 100%;
    }
    .qr-image-9 {
        display: flex;
        align-items: center;
    }
    .resume-reference-9 {
        padding: 0;
        gap: 10px;
    }
    .image-container-9 {
        width: 140px;
        height: 140px;
    }

    .contact-9,
    .education-9,
    .skill-9,
    .language-9,
    .interest-9 {
        padding-left: 10px;
    }
    .language-9 li,
    .interest-9 li {
        padding: 5px 10px 0 0;
    }
    .profile-img-9 {
        padding-bottom: 5px;
    }
}
@media screen and (max-width: 425px) {
    .resume-9 .left-side-9 {
        padding: 0 10px 20px 10px;
    }
    .resume-9 .right-side-9 {
        padding: 10px;
    }
    .resume-9 {
        display: grid;
        grid-template-columns: 65% 35%;
    }
    .resume-designation-9 {
        font-size: 16px;
    }
    .resume-gap-9 {
        margin-right: 10px;
    }
    .resume-about-9 {
        padding: 5px 0 10px 0;
    }

    .contact-details-9,
    .education-9-details-9,
    .skill-9,
    .language-9,
    .interest-9 {
        font-size: 11px;
    }
    .resume-about-9,
    .job-9-description {
        font-size: 13px;
    }
    .first-name-9 {
        margin-right: 4px;
    }
    .image-container-9 {
        width: 100px;
        height: 125px;
    }
    .contact-link-13 {
        font-size: 14px;
    }
}
