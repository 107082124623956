.main-body-2 {
    /* background: #72c3f1; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #000000;
    overflow-x: hidden;
}

.qr-image-2 img {
    width: 100px;
}
.resume-gap-2 {
    margin-right: 20px;
}
.resume-2 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    margin: 0px 0 20px 0;
    display: grid;
    grid-template-columns: 30% 70%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.resume-2 .left-side-2 {
    position: relative;
    background: #ffffff;
    padding: 30px;
    border-left: 10px solid #8c3494;
    color: #000000;
}
.profile-text-2 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.profile-img-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.profile-img-2 img {
    width: 170px;
    height: 170px;
    position: relative;
    z-index: 1;
    border: 2px solid #8c3494;
    border-radius: 50%;
    object-fit: cover;
}

.resume-2 .right-side-2 {
    position: relative;
    background: #f5f5f5;
    padding: 30px;
}
.sub-heading-2 {
    color: #8c3494;
    /* font-family: Inter; */
    text-align: left;
    font-size: 18px;
    padding: 15px 0 0 0;
    border-bottom: 2px solid #000000;
    text-transform: uppercase;
    padding-bottom: 6px;
    font-weight: 700;
}
.contact-details-2 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 4px 10px 10px 0;
    line-height: 140%;
}
.contact-heading-2 {
    margin-top: 10px;
}

.education-2-details-2 {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
}
.education-2 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.skill-2 {
    list-style-type: none;
    padding: 10px 0 10px 0;
    font-size: 14px;
}
.language-2 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
}

.language-2 li {
    padding: 5px 0px 0 0;
    box-sizing: border-box;
    font-weight: 500;
}

.interest-2 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 16px;
}

.interest-2 li {
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
}

.resume-name-2 {
    color: #8c3494;
    font-size: 42px;
    font-weight: 400;
}
.first-name-2 {
    color: #8c3494;
    font-size: 42px;
    font-weight: 900;
    margin-right: 15px;
}
.resume-2-display {
    display: flex;
    justify-content: space-between;
}
.resume-designation-2 {
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 2px;
    text-align: left;
    color: #000000;
}
.resume-about-2 {
    font-size: 16px;
    font-weight: 400;
    padding: 15px 0 20px 0;
    /* text-align: justify; */
}
.experience-2 {
    font-weight: 700;
    color: #8c3494;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.resume-experience-2 {
    position: relative;
    padding: 10px 0;
}

.job-2 {
    list-style: none;
    position: relative;
    /* padding: 0 0 20px 0; */
    display: flex;
    align-items: center;
}

.job-2-year {
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
}
.company-name-2 {
    padding: 5px 0;
}

.job-2-position {
    padding: 5px 0;
    font-size: 16px;
}

.job-2-description {
    /* text-align: justify; */
    padding-bottom: 20px;
}

.job-2:last-child .job-2-description {
    padding-bottom: 0;
}

.reference-2 {
    font-weight: 700;
    color: #8c3494;
    padding-block: 10px;
    border-bottom: 2px solid #000;
    text-transform: uppercase;
}
.resume-reference-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0;
}

.ref-2 {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
}
.resume-right-2 .ref-2 {
    color: black;
    font-size: 14px;
}
.ref-2 p {
    line-height: 15px;
}
.resume-right-2 .ref-2 span {
    font-weight: bolder;
}
.ref-name-2 {
    padding: 4px 0 4px 0;
    font-size: 18px;
}
.ref-position-2,
.ref-phone-2,
.ref-email-2 {
    padding: 3px 0 3px 0;
}
.contact-link-22,
.contact-link-2 {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-word;
}

.contact-link-22:hover {
    color: #8c3494;
    text-decoration: underline;
}
.contact-link-2:hover {
    color: #8c3494;
    text-decoration: underline;
}
/* Responsive Design */

.resume-qr-image-10{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 768px) {
    .resume-10 .left-side-resume {
        padding: 20px 10px;
    }
    .resume-10 .right-resume-content {
        padding: 20px 10px;
    }
    .resume-name {
        font-size: 20px;
    }
    .resume-name .first-name{
        margin-right: 0;
         font-size: 20px;
    }
    .main-body-15{
        transform: scale(0.95);
    }
    .resume-10 {
        grid-template-columns: 65% 35%;
    }
    .profile-img-2 img {
        width: 23vw;
        height: 23vw;
    }
    .resume-2 .right-side-2 {
        padding: 25px;
    }
    .job-content {
        padding-left: 25px;
    }
    .resume-2 .left-side-2 {
        padding: 30px 20px;
    }
    .qr-image-2 img {
        width: 15vw;
        height: 15vw;
    }
    .resume-name-2,
    .first-name-2 {
        font-size: 20px;
    }

}

@media screen and (max-width: 576px) {
    .resume-2 .left-side-2 {
        padding: 20px 12px;
    }
    .resume-gap-2 {
        margin-right: 15px;
    }

    .resume-2 .right-side-2 {
        padding: 15px;
    }
    .job-content {
        padding-left: 15px;
    }
    .sub-heading-2,
    .job-2-position,
    .ref-name-2 {
        font-size: 14px;
    }
    .company-name-2 {
        font-size: 13px;
        padding: 0;
    }
    .experience-2,
    .reference-2 {
        font-size: 16px;
    }

    .contact-link-2,
    .contact-details-2,
    .education-2-details-2,
    .skill-2,
    .language-2,
    .interest-2 {
        font-size: 13px;
        word-break: break-all;
    }
    .contact-details-2 {
        padding: 4px 10px 5px 0;
    }
    .contact-heading-2 {
        margin-top: 5px;
    }
    .resume-about-2,
    .job-2-description {
        font-size: 13px;
        line-height: 130%;
    }
    .degree-2,
    .contact-heading-2,
    .job-2-year,
    .ref-position-2,
    .ref-phone-2,
    .ref-email-2 {
        font-size: 14px;
        word-break: break-all;
    }

    .skill-2 li {
        width: 100%;
        padding: 0px 0;
    }

    .resume-designation-2 {
        font-size: 16px;
    }
    .resume-about-2 {
        padding: 10px 0 10px 0;
    }
    .job-2-description {
        padding-bottom: 15px;
    }
    .ref-2 {
        flex: 100%;
    }
    .qr-image-2 {
        display: flex;
        align-items: start !important;
    }
    .job-content-22 {
        padding-left: 0px;
    }
    .job-2::before {
        width: 7px;
        height: 7px;
    }
}
@media screen and (max-width: 425px) {
    .resume-2 .left-side-2 {
        padding: 20px 10px;
    }
    .resume-2 .right-side-2 {
        padding: 10px;
    }
    .resume-2 {
        display: grid;
        grid-template-columns: 35% 65%;
    }
    .resume-designation-2 {
        font-size: 16px;
    }
    .resume-gap-2 {
        margin-right: 10px;
    }
    .resume-about-2 {
        padding: 5px 0 10px 0;
    }
    .contact-link-2,
    .contact-details-2,
    .education-2-details-2,
    .skill-2,
    .language-2,
    .interest-2 {
        font-size: 11px;
    }

    .first-name-2 {
        margin-right: 8px;
    }
}
