.main-body-16 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #484848;
    overflow-x: hidden;
}

.qr-image-16 canvas {
    width: 100px;
    padding-top: 10px;
    vertical-align: top;
}
.resume-gap-16 {
    margin-right: 20px;
}
.resume-16 {
    position: relative;
    width: 100%;
    max-width: 790px;
    min-height: auto;
    background: #ffffff;
    display: grid;
    grid-template-columns: 30% 70%;
    border: 1px solid #ddd;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.resume-16 .left-side-16 {
    background-color: #fff;
    padding: 20px;
    color: #484848;
    position: relative;
    word-break: break-all;
}
.profile-text-16 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.profile-img-16 {
    width: 180px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.profile-img-16 img {
 width: 180px;
 height: 180px;
}

.resume-16 .right-side-16 {
    background-color: #f5f5f5;
    position: relative;
    padding: 20px;
}
.sub-heading-16 {
    color: #484848;
    text-align: left;
    font-size: 18px;
    border-bottom: 2px solid #484848;
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 0 6px 0;
}

.contact-details-16 {
    color: #484848;
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 4px 10px 10px 0;
    line-height: 140%;
}
.contact-heading-16 {
    margin-top: 10px;
    color: #484848;
}

.education-16-details-16 {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
    padding: 3px 0 0 0;
}
.education-16 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.skill-16 {
    padding: 10px 0 10px 0px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
}

.skill-16 li {
    width: 100%;
    padding: 5px 0;
    color: #484848;
}
.language-16 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 14px;
}

.language-16 li {
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    color: #484848;
}

.interest-16 {
    list-style: none;
    margin: 0;
    padding: 5px 0 10px 0;
    position: relative;
    font-size: 16px;
}

.interest-16 li {
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    color: #484848;
}

.resume-name-16 {
    color: #484848;
    font-size: 20px;
    font-weight: 400;
}
.first-name-16 {
    color: #484848;
    font-size: 20px;
    font-weight: 900;
    margin-right: 7px;
}
.resume-16-display {
    display: flex;
    justify-content: space-between;
}
.resume_16_name_designation {
    padding: 18px 0;
}
.resume-designation-16 {
    font-weight: 400;
    line-height: 19px;
    font-size: 18px;
    color: #484848;
    padding-top: 8px;
}
.resume-about-16 {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0 20px 0;
}
.experience-16 {
    font-weight: 700;
    color: #484848;
    border-bottom: 2px solid #484848;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 18px;
}

.resume-experience-16 {
    position: relative;
    padding: 20px 0 10px 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.education-16 {
    position: relative;
    padding: 20px 0 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.job-16 {
    list-style: none;
    display: flex;
    align-items: center;
}

.job-16-year {
    /* margin-bottom: 5px; */
    position: relative;
    z-index: 2;
}
.resume-education-16 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 15px 0;
}

.company-name-16 {
    padding: 2px 0;
}
.job-content-16 {
    gap: 10px;
    display: grid;
    grid-template-columns: 40% 60%;
}

.job-16-position {
    color: #484848;
    padding: 2px 0;
    font-size: 16px;
}

.job-16-description {
    padding-bottom: 20px;
}

.job-16:last-child .job-16-description {
    padding-bottom: 0;
}

.reference-16 {
    font-weight: 700;
    color: #484848;
    padding-block: 10px;
    border-bottom: 2px solid #484848;
    text-transform: uppercase;
    font-size: 18px;
}
.resume-reference-16 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0 0px 0;
}

.ref-16 {
    flex: 0 1 calc(50% - 10px);
    box-sizing: border-box;
}
.resume-right-16 .ref-16 {
    color: black;
    font-size: 14px;
}
.ref-16 p {
    line-height: 15px;
}
.resume-right-16 .ref-16 span {
    font-weight: bolder;
}
.ref-name-16 {
    padding: 4px 0 4px 0;
    font-size: 18px;
}
.ref-position-16,
.ref-phone-16,
.ref-email-16 {
    padding: 3px 0 3px 0;
}

.contact-link-16 {
    color: inherit;
    text-decoration: none;
    word-wrap: break-word;
    word-break: break-word;
}
.contact-link-16:hover {
    color: #484848;
}
.resume-contact-details-16 {
    padding: 5px 20px 20px;
}
.degree-16 {
    color: #484848;
}

@media screen and (max-width: 768px) {
    .main-body-16 {
        transform: scale(0.95);
    }
    .resume-experience-16 {
        grid-template-columns: 1fr;
    }
    .education-16 {
    grid-template-columns: 1fr;
}
    .resume-name-16,
    .first-name-16 {
        font-size: 20px;
    }
    .profile-img-16{
        width: 100%;
    }
    .profile-img-16 img {
        width: 100%;
        height: auto;
    }
    .resume-16 .right-side-16 {
        padding: 25px;
    }
    .resume-16 .left-side-16 {
        padding: 30px 20px;
    }
    .qr-image-16 canvas {
        width: 13vw !important;
        height: 13vw !important;
    }
    .job-content-16 {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 525px) {
    .resume-experience-16 {
        padding: 14px 0 10px 0;
    }
    .profile-img-16 img {
        width: 100%;
        height: auto;
    }
    .resume-16 {
        grid-template-columns: 35% 65%;
    }
    .resume-16 .left-side-16 {
        padding: 12px;
    }
    .resume-gap-16 {
        margin-right: 15px;
    }

    .resume-16 .right-side-16 {
        padding: 15px;
    }
    .sub-heading-16,
    .job-16-position,
    .ref-name-16 {
        font-size: 14px;
    }
    .company-name-16 {
        font-size: 13px;
        padding: 3px 0;
    }
    .experience-16,
    .reference-16 {
        font-size: 16px;
    }

    .contact-link-16,
    .contact-details-16,
    .education-16-details-16,
    .skill-16,
    .language-16,
    .interest-16 {
        font-size: 13px;
    }
    .contact-details-16 {
        padding: 4px 10px 5px 0;
    }
    .contact-heading-16 {
        margin-top: 5px;
    }
    .resume-about-16,
    .job-16-description {
        font-size: 14px;
    }
    .degree-16,
    .contact-heading-16,
    .job-16-year,
    .ref-position-16,
    .ref-phone-16,
    .ref-email-16 {
        font-size: 14px;
    }

    .skill-16 li {
        width: 100%;
        padding: 0px 0;
    }
    .resume-name-16,
    .first-name-16 {
        font-size: 18px;
    }
    .resume-designation-16 {
        font-size: 14px;
        padding-top: 3px;
    }
    .resume-about-16 {
        padding: 0px 0 10px 0;
    }
    .job-16-description {
        padding-bottom: 15px;
    }
    .ref-16 {
        flex: 100%;
    }

    .qr-image-16 canvas {
        width: 12vw;
        padding-top: 0;
    }
    .resume-education-16 {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .resume-reference-16 {
        gap: 10px;
    }
    .language-16 li,
    .interest-16 li {
        padding: 5px 10px 0 0;
    }
    .skill-16 {
        padding: 10px 0 10px 0px;
    }
    .resume_16_name_designation {
        padding: 18px 0 10px 0;
    }
}
@media screen and (max-width: 425px) {
    .resume-name-16,
    .first-name-16 {
        font-size: 16px;
    }
    .sub-heading-16 {
        padding-top: 10px;
    }

    .resume_16_name_designation {
        padding: 15px 0 10px 0;
    }
    .resume-16 .left-side-16 {
        padding: 15px 10px;
    }
    .resume-16 .right-side-16 {
        padding: 10px;
    }
    .resume-16 {
        display: grid;
        grid-template-columns: 35% 65%;
    }
    .resume-designation-16 {
        font-size: 14px;
    }
    .resume-gap-16 {
        margin-right: 10px;
    }
    .resume-about-16 {
        padding: 5px 0 10px 0;
    }

    .contact-details-16,
    .education-16-details-16,
    .skill-16,
    .language-16,
    .interest-16 {
        font-size: 11px;
    }
    .contact-link-16 {
        font-size: 13px;
    }
    .resume-about-16,
    .job-16-description {
        font-size: 13px;
    }
    .first-name-16 {
        margin-right: 5px;
    }
    .qr-image-16 canvas {
        padding-top: 5px;
    }
    .experience-16,
    .reference-16 {
        padding: 5px 0;
    }
    .degree-16,
    .contact-heading-16,
    .job-16-year,
    .ref-position-16,
    .ref-phone-16,
    .ref-email-16 {
        font-size: 13px;
    }
    .job-content-16 {
        gap: 5px;
    }
}
@media screen and (max-width: 425px) {
    .resume-designation-16 {
        font-size: 13px;
    }
    .resume-name-16,
    .first-name-16 {
        font-size: 14px;
    }
    .resume-about-16,
    .job-16-description {
        font-size: 12px;
    }
}
